import './styles.scss';

import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  message,
  Modal,
  Typography,
} from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import CourseAPI from '../../../api/CourseAPI';
import Header from '../../../components/Header/Header';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { Creator } from '../../../types/courseTypes';
import {
  IQNA,
  IQNAReply,
} from '../../../types/qnaTypes';
import QNAItem from '../QNAItem/QNAItem';
import QNAReplyItem from '../QNAReplyItem/QNAReplyItem';
import ReplyInputBox from './ReplyInputBox';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  chapterId: string;
  qna: IQNA;
  creator: Creator;
  onRefresh: () => void;
}

interface IQnAState {
  qna: IQNAReply[];
  hasMore: boolean;
  page: number;
  loading: boolean;
  refreshing: boolean;
}

const defaultState: IQnAState = {
  loading: false,
  page: 1,
  hasMore: true,
  qna: [],
  refreshing: false,
};

const PAGE_LIMIT = 10;

const QNAReplies: React.FC<Props> = ({
  showModal,
  closeModal,
  qna,
  creator,
  chapterId,
  onRefresh,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const [replyCount, setReplyCount] = useState(qna.replyCount);
  const [qnaState, setQnaState] = useState<IQnAState>(defaultState);

  const getQnAPaginated = useCallback(
    async (
      pageCount: number,
      qnaList = qnaState.qna,
      hasMore = qnaState.hasMore,
      loading = qnaState.loading,
      currState = qnaState,
    ) => {
      if (hasMore && !loading) {
        setQnaState({ ...currState, loading: true });
        try {
          const response = await CourseAPI.getQnaReplyList(
            qna._id,
            pageCount,
            PAGE_LIMIT,
          );
          const res = response.data;
          let newState = {
            ...currState,
            loading: false,
            refreshing: false,
          };
          if (response.status === 200) {
            if (res.result && res.result.length) {
              const updatedQna =
                pageCount > 1
                  ? [...(qnaList || []), ...res.result]
                  : [...res.result];
              newState = {
                ...newState,
                qna: updatedQna,
                page: pageCount + 1,
                hasMore: res.result.length === PAGE_LIMIT,
              };
            } else {
              newState = {
                ...newState,
                qna: [...(qnaList || [])],
                hasMore: false,
              };
            }
          }
          setQnaState({ ...qnaState, ...newState });
        } catch (error: any) {
          setQnaState({ ...qnaState, loading: false });
        }
      }
    },
    [qna._id, qnaState],
  );

  const loadQNA = useCallback(() => {
    if (showModal) {
      const newState: IQnAState = {
        qna: [],
        hasMore: true,
        page: 1,
        loading: false,
        refreshing: false,
      };
      setQnaState((prev) => ({
        ...prev,
        qna: [],
        hasMore: true,
        page: 1,
      }));
      getQnAPaginated(1, [], true, false, newState);
    }
  }, [showModal, getQnAPaginated]);

  useEffect(() => {
    if (qna.replyCount > 0) loadQNA();
    else setQnaState({ ...qnaState, hasMore: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qna]);

  const removeQNAItem = async (qnaId: string) => {
    const ind = qnaState.qna.findIndex((i) => i._id === qnaId);
    const item = qnaState.qna[ind];
    setQnaState((prev) => ({
      ...prev,
      qna: prev.qna.filter((q) => q._id !== qnaId),
    }));
    setReplyCount((prev) => prev - 1);
    try {
      const res = await CourseAPI.removeQNA(qnaId);
      if (res.status === 200) {
        message.success('Reply successfully removed!!!');
        onRefresh();
      }
    } catch (error) {
      console.log(error);
      message.error('Error removing reply!!!');
      setQnaState((prev) => ({
        ...prev,
        qna: prev.qna.splice(ind, 0, item),
      }));
      setReplyCount((prev) => prev + 1);
    }
  };

  const footer = React.useMemo(() => {
    if (qnaState.loading || qnaState.hasMore)
      return (
        <Loader
          style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}
        />
      );
    if (qnaState.qna.length === 0)
      return (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '45px 0',
          }}>
          Whoops! No replies posted!
        </Typography.Paragraph>
      );
    return (
      <Typography.Paragraph
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '20px 0',
        }}>
        That’s it! No more replies to show.
      </Typography.Paragraph>
    );
  }, [qnaState.hasMore, qnaState.loading, qnaState.qna.length]);

  return (
    <Modal
      open={showModal}
      title={<Header title="Replies" handleBack={closeModal} />}
      destroyOnClose
      className="qnaRepliesModal"
      closable={false}
      footer={
        <ReplyInputBox
          onAddReply={(reply: IQNAReply) => {
            if (!qnaState.loading) {
              setQnaState((prev) => ({
                ...prev,
                qna: [...prev.qna, reply],
              }));
              setReplyCount((prev) => prev + 1);
              onRefresh();
            }
          }}
          chapterId={chapterId}
          qnaId={qna._id}
        />
      }
      closeIcon={null}>
      <QNAItem
        question={qna.title}
        description={qna.description}
        createdAt={qna.createdAt}
        imgUrl={qna.imgUrl}
        userName={qna.userName}
        replyCount={qna.replyCount}
        attachments={qna.attachments}
        containerStyle={{
          backgroundColor: colors.TERTIARY,
          marginBottom: 6,
        }}
      />
      {replyCount > 0 && (
        <div className="replyCount">
          <Typography.Text className="replyCountText">
            {replyCount} repl{replyCount > 1 ? 'ies' : 'y'}
          </Typography.Text>
        </div>
      )}
      <EasyVirtualizedScroller
        useParentScrollElement
        key={qnaState.qna.map((item) => item._id).join('')}
        hasMore={qnaState.hasMore}
        onLoadMore={() => getQnAPaginated(qnaState.page, qnaState.qna)}>
        {qnaState.qna.map((item) => (
          <QNAReplyItem
            key={item._id}
            description={item.description}
            createdAt={item.createdAt}
            imgUrl={item.imgUrl}
            userName={item.userName}
            isCreator={item.userId === creator._id}
            attachments={item.attachments}
            containerStyle={{
              backgroundColor: colors.BACKGROUND,
              borderBottom: `1px solid ${colors.BORDER}`,
            }}
            showMenu={
              userDetails.id === item.userId || userDetails.id === creator._id
            }
            onRemoveItem={() => removeQNAItem(item._id)}
          />
        ))}
      </EasyVirtualizedScroller>
      {footer}
    </Modal>
  );
};
export default QNAReplies;
