import React, { useState } from 'react';

import { message, Typography } from 'antd';

import UserAPI from '../../../../api/UserAPI';
import HeaderTextAction from '../../../../components/Header/actions/HeaderTextAction';
import Header from '../../../../components/Header/Header';
import Input from '../../../../components/Input/Input';
import { useAppNavigate, useAppSelector } from '../../../../shared/hooks';
import { emailRegex } from '../../../../shared/utils';
import { ROUTES } from '../../../../types/routes';

const title = 'Change Email';
const subtitle =
  'Please enter your email below to generate an OTP for validation';

interface IError {
  validateStatus: 'info' | 'error' | 'success';
  errorMsg: string;
}

const ChangeEmail: React.FC = () => {
  const navigate = useAppNavigate();
  const userDetails = useAppSelector((state) => state.user);
  const [error, setError] = useState<IError | null>(null);
  const [email, setEmail] = useState<string>(userDetails.email || '');
  const [saving, setSaving] = useState<boolean>(false);

  const validateEmail = (newEmail: string) => {
    const emailData: IError = {
      validateStatus: 'success',
      errorMsg: 'Valid email',
    };

    if (!newEmail) {
      emailData.validateStatus = 'error';
      emailData.errorMsg = 'Please enter email.';
    }

    console.log(newEmail, emailRegex.test(newEmail));

    if (newEmail && !emailRegex.test(newEmail)) {
      emailData.validateStatus = 'error';
      emailData.errorMsg = 'Please enter a valid email address';
    }

    setError(emailData);

    return emailData.validateStatus === 'success';
  };

  const handleSave = async (newEmail: string = email) => {
    if (validateEmail(newEmail)) {
      try {
        setSaving(true);
        const resp = await UserAPI.getChangeEmailOTP(newEmail);
        if (resp.status === 200) {
          navigate(
            ROUTES.VERIFY_OTP_ACCOUNT,
            {},
            {
              state: {
                email: newEmail,
                mode: 'email',
                countryCode: 'IN',
                blockOtp: false,
                supportNumber: null,
                flow: 'email',
              },
            },
          );
          message.success('OTP sent successfully');
        } else {
          message.error(resp.data.message);
        }
      } catch (err: any) {
        if (
          err.response?.data?.result &&
          typeof err.response.data.result === 'string'
        ) {
          message.error(err.response.data.result);
        } else if (err.response?.data?.message)
          message.error(err.response.data.message);
        else message.error('Something went wrong.');
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div className="pageRoot">
      <Header
        title={title}
        actionItems={[
          <HeaderTextAction
            text="Get OTP"
            onClick={() => handleSave()}
            loadingText="Get OTP"
            loading={saving}
          />,
        ]}
      />
      <div className="pageContent" style={{ padding: 12 }}>
        <Typography.Paragraph className="pageSubtitle">
          {subtitle}
        </Typography.Paragraph>
        <Input
          disabled={saving}
          label="Email"
          type="email"
          text={email}
          onChangeText={(text) => {
            setEmail(text);
          }}
          message={
            error
              ? {
                  type: error.validateStatus,
                  text: error.errorMsg,
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default ChangeEmail;
