/* eslint-disable react-hooks/exhaustive-deps */
import './styles.scss';

import React, { useEffect, useState } from 'react';

import { message, Typography } from 'antd';
import { useParams } from 'react-router-dom';

import UserAPI from '../../api/UserAPI';
import Header from '../../components/Header/Header';
import {
  getPosts,
  setIsUserProfilePage,
  setPostState,
  setSelected,
} from '../../context/activityReducer';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { formatName } from '../../shared/utils';
import { IPointsConfig } from '../../types/HostMetadata';
import PostsList from '../Feed/Posts/PostsList';
import UserProfileCard from './components/UserProfileCard';
import UserProfileSkeleton from './components/UserProfileSkeleton';
import { IProfileDetailsResponse } from './types';

interface Props {
  handleBack?: () => void;
}

const UserProfile: React.FC<Props> = ({ handleBack }) => {
  // const navigate = useAppNavigate();

  const { userId } = useParams();

  const dispatch = useAppDispatch();

  const {
    isTagMango,
    isLoggedIn,
    hostMetadata: { pointsConfig, offeringTitles: mangoesTitle },
  } = useAppSelector((state) => state.app);
  const { selected, isUserProfilePage } = useAppSelector(
    (state) => state.activity,
  );
  const { id } = useAppSelector((state) => state.user);

  const [profileDetails, setProfileDetails] =
    useState<IProfileDetailsResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const reset = () => {
  //   dispatch(
  //     setPostState({
  //       posts: [],
  //       page: 1,
  //     }),
  //   );
  //   dispatch(setSelected(''));
  //   dispatch(setIsUserProfilePage(false));
  // };

  // const onBack = () => {
  //   reset();

  //   if (navigate.canGoBack()) {
  //     navigate.goBack();
  //   } else {
  //     navigateToOnboardingScreen(type, navigate);
  //   }
  // };

  const fetchProfileDetails = async () => {
    if (!userId) return;

    setIsLoading(true);
    try {
      const response = await UserAPI.getProfileDetails(userId);
      setProfileDetails(response.data.result);
    } catch (error) {
      message.error('Failed to fetch profile details');
    } finally {
      setIsLoading(false);
    }
  };

  const reloadUserPosts = () => {
    const newState = {
      posts: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };

    dispatch(
      setPostState({
        posts: [],
        hasMore: true,
        page: 1,
      }),
    );

    if (selected === userId) {
      dispatch(
        getPosts({
          pageCount: 1,
          postList: [],
          hasMore: true,
          loading: false,
          currState: newState,
          showUserPosts: true,
        }),
      );
    }
  };

  useEffect(() => {
    if (isLoggedIn && id && userId) {
      dispatch(setIsUserProfilePage(true));
      if (!isTagMango) {
        dispatch(setSelected(userId));
      }
      fetchProfileDetails();
    } else {
      message.error('Please login to view profile');
    }
  }, [id, isLoggedIn, userId, isTagMango]);

  // On refresh, set userId as selected
  useEffect(() => {
    if (!isTagMango && isUserProfilePage && userId && selected !== userId) {
      dispatch(setSelected(userId));
    }
  }, [isUserProfilePage, userId, selected, isTagMango]);

  useEffect(() => {
    if (selected === userId) {
      reloadUserPosts();
    }
  }, [selected, userId]);

  return (
    <>
      <div className="home__container user-profile__container">
        <Header title="Profile" handleBack={handleBack} />

        <div className="user-profile__content">
          <div className="user-profile-card">
            {!isLoading && profileDetails ? (
              <UserProfileCard
                profileDetails={profileDetails as IProfileDetailsResponse}
                setProfileDetails={setProfileDetails}
                pointsConfig={pointsConfig as IPointsConfig}
                isTagMango={isTagMango}
                mangoesTitle={mangoesTitle}
                userId={userId as string}
              />
            ) : (
              <UserProfileSkeleton />
            )}
          </div>

          {!isTagMango &&
          !isLoading &&
          profileDetails &&
          selected === userId ? (
            <div className="user-profile-posts">
              <Typography.Title level={5} style={{ margin: 12 }}>
                Community Posts
              </Typography.Title>
              <PostsList
                userId={userId}
                isUserProfilePage={isUserProfilePage}
                showUserPosts
                noPostsMessage={`Posts from ${formatName(
                  profileDetails.userProfile.name,
                  {
                    nameType: 'first',
                    caseType: 'title',
                  },
                )} will appear here.`}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
