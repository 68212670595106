import './styles.scss';

import { useState } from 'react';

import { Button, message, Typography } from 'antd';
import { MdWarning } from 'react-icons/md';

import UserAPI from '../../../../api/UserAPI';
import Header from '../../../../components/Header/Header';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { clearActivity } from '../../../../context/activityReducer';
import { setLoggedIn } from '../../../../context/appReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import { clearUserDetails } from '../../../../context/userReducer';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { setLocalData } from '../../../../shared/utils';
import { ROUTES } from '../../../../types/routes';
import activate from './activate.png';

const title = 'Delete my account';

const DeleteAccount: React.FC = () => {
  const {
    hostMetadata: { offeringTitles },
  } = useAppSelector((state) => state.app);
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const options = [
    'This can be permanent',
    'Delete your account',
    'Erase all your data',
    `Delete all your ${offeringTitles} and subscriptions`,
    'Delete all your posts',
    'Delete all your payment history and invoices',
  ];

  const deleteAccount = async () => {
    setIsLoaderVisible(true);
    try {
      const resp = await UserAPI.deactivateAccount('delete');
      if (resp.status === 200) {
        setLocalData(null);
        dispatch(setLoggedIn(false));
        dispatch(clearActivity());
        dispatch(clearUserDetails());
        message.error(`Account successfully deleted`);
        setTimeout(() => {
          navigate(ROUTES.LOGIN);
        }, 100);
      } else {
        message.error(`Could not delete your account try again!!`);
      }
    } catch (err) {
      message.error('Something went wrong');
    } finally {
      setIsLoaderVisible(false);
    }
  };

  return (
    <>
      <div className="pageRoot">
        <Header title={title} />
        <div className="pageContent deleteAccount">
          <img src={activate} alt="Delete" className="deleteAccount__image" />
          <div className="deleteAccount__title">
            <MdWarning color={colors.DANGER} size={24} />
            <Typography.Title level={5} className="deleteAccount__title--text">
              Deleting your account will:
            </Typography.Title>
          </div>
          <ul className="deleteAccount__list">
            {options.map((option) => (
              <li key={option} className="deleteAccount__list--item">
                {option}
              </li>
            ))}
          </ul>
          <Typography.Paragraph className="deleteAccount__text">
            Your account can be restored on re-login within 30 days.
          </Typography.Paragraph>
        </div>
        <div className="pageFooter">
          <Button
            className="siteBtn siteBtnDanger"
            onClick={() => setModalVisible(true)}
            loading={isLoaderVisible}>
            Delete my account
          </Button>
        </div>
      </div>
      <ConfirmationModal
        open={isModalVisible}
        message="Are you sure you want to delete?"
        title="Confirm"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          deleteAccount();
          setModalVisible(false);
        }}
        handleCancel={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default DeleteAccount;
