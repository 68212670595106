import './styles.scss';

import React from 'react';

import { Divider, Modal, Typography } from 'antd';
import { MdCheck, MdClose } from 'react-icons/md';

import TickSvg from '../../../assets/svg/TickSvg';
import Header from '../../../components/Header/Header';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { IAssessmentReview } from '../../../types/assessmentTypes';
import ReviewAnswersheetItem from './ReviewAnswersheetItem/ReviewAnswersheetItem';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  assignment: IAssessmentReview;
}

const ReviewAnswersheet: React.FC<Props> = ({
  showModal,
  closeModal,
  assignment,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);

  const generateFields = (fieldName: string, fieldValue: string) => (
    <li className="reviewAnswersheetModal__header__list__item">
      <Typography.Text className="reviewAnswersheetModal__header__list__item__text">
        {fieldName}: <Typography.Text>{fieldValue}</Typography.Text>
      </Typography.Text>
    </li>
  );

  return (
    <Modal
      open={showModal}
      title={
        <Header
          title="Review Answersheet"
          backType="cross"
          handleBack={closeModal}
        />
      }
      destroyOnClose
      className="reviewAnswersheetModal"
      closable={false}
      footer={null}>
      <div className="reviewAnswersheetModal__header">
        <Typography.Title
          className="reviewAnswersheetModal__header__title"
          ellipsis={{
            rows: 3,
            expandable: true,
            symbol: 'See more',
          }}
          level={4}>
          {assignment.fetchedAssignment.title}
        </Typography.Title>
        <div className="reviewAnswersheetModal__header__question-pill">
          <TickSvg
            width={14}
            height={14}
            circleColor={colors.PRIMARY}
            backgroundColor={colors.BACKGROUND}
          />
          <Typography.Text className="reviewAnswersheetModal__header__question-pill__text">
            {assignment.questions?.length || 0} question
            {assignment.questions?.length !== 1 ? 's' : ''}
          </Typography.Text>
        </div>
        <Divider />
        <ul className="reviewAnswersheetModal__header__list">
          {generateFields('Name', userDetails.name || '')}
          {generateFields(
            'No. of questions attempted',
            (assignment.questionsAnswered || 0).toString(),
          )}
        </ul>
        <div
          className="reviewAnswersheetModal__header__status"
          style={{
            backgroundColor: `${
              assignment.status === 'pass' ? colors.GREEN : colors.DANGER
            }30`,
          }}>
          <div
            className="reviewAnswersheetModal__header__status__icon"
            style={{
              backgroundColor:
                assignment.status === 'pass' ? colors.GREEN : colors.DANGER,
            }}>
            {assignment.status === 'pass' ? (
              <MdCheck color={colors.BACKGROUND} />
            ) : (
              <MdClose color={colors.BACKGROUND} />
            )}
          </div>
          <Typography.Text className="reviewAnswersheetModal__header__status__text">
            {assignment.status === 'pass'
              ? 'Congratulations! You passed this test.'
              : 'Sorry! You failed this test.'}
          </Typography.Text>
        </div>
      </div>
      <div className="reviewAnswersheetModal__body">
        {assignment.questions.map((item) => (
          <ReviewAnswersheetItem key={item._id} question={item} />
        ))}
      </div>
    </Modal>
  );
};

export default React.memo(ReviewAnswersheet);
