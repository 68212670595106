import './styles.scss';

import React, { useEffect, useMemo } from 'react';

import { Button, message } from 'antd';
import moment from 'moment';
import { IoAdd, IoPricetag } from 'react-icons/io5';

import FeedAPI from '../../api/FeedAPI';
import HeaderTextAction from '../../components/Header/actions/HeaderTextAction';
import Header from '../../components/Header/Header';
import Select, { SINGLE } from '../../components/Input/Select';
import TextArea from '../../components/Input/TextArea';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import { onRefresh } from '../../context/activityReducer';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import { IMango } from '../../types/feedTypes';
import CreatePostAttachment from './components/CreatePostAttachment';
import CreatePostHeader, {
  CreatePostHeaderRef,
} from './components/CreatePostHeader';
import OptionTextField from './components/OptionTextField';

const DayMap: Record<number, string> = {
  1: '1 day',
  3: '3 days',
  7: '1 week',
  14: '2 weeks',
};

const MAX_POLLS = 5;

const CreatePoll = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { creatorMangoes, subscribedMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );
  const {
    hostMetadata: { offeringTitle, communityEnabledMangoes },
  } = useAppSelector((state) => state.app);

  const createPostHeaderRef = React.useRef<CreatePostHeaderRef>(null);

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [selectedMangoes, setSelectedMangoes] = React.useState<IMango[]>([]);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] =
    React.useState(false);
  const [question, setQuestion] = React.useState('');
  const [options, setOptions] = React.useState(['', '']);
  const [duration, setDuration] = React.useState<keyof typeof DayMap>(1);
  const [isSaving, setIsSaving] = React.useState(false);

  const postContentOptions: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  }[] = useMemo(() => {
    const iconSize = 24;
    const items = [
      {
        icon: <IoPricetag size={iconSize} />,
        label: 'Tags',
        onClick: () => {
          createPostHeaderRef.current?.openTagModal();
        },
      },
    ];
    return items;
  }, []);

  const submit = async () => {
    try {
      setIsSaving(true);
      const response = await FeedAPI.createPoll(
        question.trim(),
        options.map((option) => option.trim()),
        selectedMangoes.map((mango) => mango._id),
        new Date(),
        moment().add(duration, 'days').toDate(),
        selectedTags,
      );
      if (response.status === 200) {
        dispatch(onRefresh());
        navigate.goBack();
        message.success('Poll created successfully');
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      message.error('Failed to publish the poll');
    } finally {
      setIsSaving(false);
    }
  };

  const submitPost = async () => {
    if (selectedMangoes.length === 0) {
      message.error(`Please select ${offeringTitle} to publish`);
      return;
    }
    if (question?.trim().length === 0) {
      message.error('Please enter the question');
      return;
    }
    if (options.length < 2) {
      message.error('Please enter atleast 2 options');
      return;
    }
    if (options.length > MAX_POLLS) {
      message.error(`Please enter maximum ${MAX_POLLS} options`);
      return;
    }
    if (options.some((option) => option?.trim().length === 0)) {
      message.error('Please enter all the options');
      return;
    }
    setIsConfirmDialogVisible(true);
  };

  const isDisabled = useMemo(
    () =>
      selectedMangoes.length === 0 ||
      question?.trim().length === 0 ||
      options.some((option) => option?.trim().length === 0),
    [options, question, selectedMangoes.length],
  );

  const mangoesToDisplay = useMemo(() => {
    if (
      ['creator_completed', 'dual', 'creator_restricted'].includes(
        userDetails.type || '',
      )
    ) {
      return creatorMangoes;
    } else if (
      subscribedMangoes.length > 0 &&
      communityEnabledMangoes &&
      communityEnabledMangoes.length > 0 &&
      ['fan_completed'].includes(userDetails.type || '')
    ) {
      const subscribedMangoesWithCommunityEnabled = subscribedMangoes.filter(
        (mango) => communityEnabledMangoes.includes(mango._id),
      );
      return subscribedMangoesWithCommunityEnabled;
    }
    return [];
  }, [
    creatorMangoes,
    userDetails.type,
    subscribedMangoes,
    communityEnabledMangoes,
  ]);

  useEffect(() => {
    if (
      selectedMangoes.length === 0 &&
      mangoesToDisplay &&
      mangoesToDisplay.length === 1
    ) {
      setSelectedMangoes([mangoesToDisplay[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mangoesToDisplay]);

  const onOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const onAddOption = () => {
    if (options.length >= MAX_POLLS) {
      message.error(`You can only add up to ${MAX_POLLS} options`);
      return;
    }
    setOptions([...options, '']);
  };

  const onOptionDelete = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };
  return (
    <>
      <div className="createpost__container createpoll">
        <Header
          title="Create Poll"
          backType="cross"
          actionItems={[
            <HeaderTextAction
              text="Post"
              disabled={isDisabled}
              onClick={submitPost}
              loading={isSaving}
              loadingText="Saving..."
            />,
          ]}
        />
        <div className="scroll__container">
          <CreatePostHeader
            ref={createPostHeaderRef}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedMangoes={selectedMangoes}
            setSelectedMangoes={setSelectedMangoes}
          />
          <div className="createpoll__question">
            <TextArea
              label="Question"
              text={question}
              onChangeText={setQuestion}
              maxLength={140}
            />
          </div>
          {options.map((option, index) => (
            <OptionTextField
              key={index.toString()}
              option={option}
              index={index}
              onOptionChange={onOptionChange}
              onOptionDelete={onOptionDelete}
              canRemove={options.length > 2}
              maxLength={30}
              style={{
                marginTop: 16,
              }}
            />
          ))}
          <Button
            className="siteBtn siteBtnPrimary light createpoll_add-button"
            onClick={onAddOption}>
            <IoAdd size={16} />
            Add Option
          </Button>
          <Select<SINGLE>
            label="Poll Duration"
            selected={duration.toString()}
            onChange={(value) => {
              setDuration(parseInt(value));
            }}
            options={Object.keys(DayMap)}
            getLabel={(value) => DayMap[parseInt(value, 10)]}
          />
        </div>
        <CreatePostAttachment options={postContentOptions} />
      </div>
      <ConfirmationModal
        open={isConfirmDialogVisible}
        message={`Your poll will last till ${DayMap[duration]}. Do you want to continue?`}
        title="Create"
        okayButtonText="Yes"
        cancelButtonText="Cancel"
        handleOk={() => {
          submit();
          setIsConfirmDialogVisible(false);
        }}
        handleCancel={() => {
          setIsConfirmDialogVisible(false);
        }}
      />
    </>
  );
};

export default CreatePoll;
