import * as React from 'react';

import { Avatar, message, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import CourseAPI from '../../../api/CourseAPI';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { Creator } from '../../../types/courseTypes';
import { IQNA } from '../../../types/qnaTypes';
import AskAQuestion from '../AskAQuestion/AskAQuestion';
import QNAItem from '../QNAItem/QNAItem';
import QNAReplies from '../QNAReplies/QNAReplies';

interface QnASectionProps {
  chapterId: string;
  creator: Creator;
}

interface IQnAState {
  qna: IQNA[];
  hasMore: boolean;
  page: number;
  loading: boolean;
  refreshing: boolean;
}

const defaultState: IQnAState = {
  loading: false,
  page: 1,
  hasMore: true,
  qna: [],
  refreshing: false,
};

const PAGE_LIMIT = 10;

interface IReplies {
  chapterId: string;
  qna: IQNA;
  creator: Creator;
}

const QnASection: React.FC<QnASectionProps> = ({ chapterId, creator }) => {
  const [qnaState, setQnaState] = React.useState<IQnAState>(defaultState);
  const [showModal, setShowModal] = React.useState(false);
  const [showReplies, setShowReplies] = React.useState<IReplies | null>(null);
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);

  const getQnAPaginated = React.useCallback(
    async (
      pageCount: number,
      qnaList = qnaState.qna,
      hasMore = qnaState.hasMore,
      loading = qnaState.loading,
      currState = qnaState,
    ) => {
      if (hasMore && !loading) {
        setQnaState({ ...currState, loading: true });
        try {
          const response = await CourseAPI.getQnaList(
            chapterId,
            pageCount,
            PAGE_LIMIT,
          );
          const res = response.data;
          let newState = { ...currState, loading: false, refreshing: false };
          if (response.status === 200) {
            if (res.result && res.result.length) {
              const updatedQna =
                pageCount > 1
                  ? [...(qnaList || []), ...res.result]
                  : [...res.result];
              newState = {
                ...newState,
                qna: updatedQna,
                page: pageCount + 1,
                hasMore: res.result.length === PAGE_LIMIT,
              };
            } else {
              newState = {
                ...newState,
                qna: [...(qnaList || [])],
                hasMore: false,
              };
            }
          }
          setQnaState({ ...qnaState, ...newState });
        } catch (error: any) {
          setQnaState({ ...qnaState, loading: false });
        }
      }
    },
    [chapterId, qnaState],
  );

  const loadQNA = React.useCallback(() => {
    const newState: IQnAState = {
      qna: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setQnaState((prev) => ({
      ...prev,
      qna: [],
      hasMore: true,
      page: 1,
    }));
    getQnAPaginated(1, [], true, false, newState);
  }, [getQnAPaginated]);

  const removeQNAItem = async (qnaId: string) => {
    const ind = qnaState.qna.findIndex((i) => i._id === qnaId);
    const item = qnaState.qna[ind];
    setQnaState((prev) => ({
      ...prev,
      qna: prev.qna.filter((qna) => qna._id !== qnaId),
    }));
    const m = message.loading('Deleting question...');
    try {
      const res = await CourseAPI.removeQNA(qnaId);
      if (res.status === 200) {
        m();
        message.success('Comment successfully removed!!!');
      }
    } catch (error) {
      console.log(error);
      m();
      message.error('Error removing question!!!');
      setQnaState((prev) => ({
        ...prev,
        qna: prev.qna.splice(ind, 0, item),
      }));
    } finally {
      m();
    }
  };

  React.useEffect(() => {
    loadQNA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterId]);

  const footer = React.useMemo(() => {
    if (qnaState.loading || qnaState.hasMore)
      return (
        <Loader
          style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}
        />
      );
    if (qnaState.qna.length === 0)
      return (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '45px 0',
          }}>
          Whoops! No questions posted!
        </Typography.Paragraph>
      );
    return (
      <Typography.Paragraph
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '20px 0',
        }}>
        That’s it! No more questions to show.
      </Typography.Paragraph>
    );
  }, [qnaState.hasMore, qnaState.loading, qnaState.qna.length]);

  const header = React.useMemo(
    () => (
      <div>
        <Typography.Title level={4} className="chapter__section__title">
          Questions
        </Typography.Title>
        <div
          className="qna__section__header"
          onClick={() => {
            setShowModal(true);
          }}>
          <Avatar src={userDetails.profilePic || ''} size={38} />
          <Typography.Text className="qna__section__header__text">
            Ask a question...
          </Typography.Text>
        </div>
      </div>
    ),
    [userDetails.profilePic],
  );

  return (
    <>
      {header}
      {qnaState.qna.length > 0 ? (
        <EasyVirtualizedScroller
          useParentScrollElement
          hasMore={qnaState.hasMore}
          onLoadMore={() => getQnAPaginated(qnaState.page, qnaState.qna)}>
          {qnaState.qna.map((item) => (
            <QNAItem
              key={item._id}
              question={item.title}
              description={item.description}
              createdAt={item.createdAt}
              imgUrl={item.imgUrl}
              userName={item.userName}
              attachments={item.attachments}
              replyCount={item.replyCount}
              containerStyle={{
                borderBottom: `1px solid ${colors.BORDER}`,
              }}
              showMenu={
                userDetails.id === item.userId || userDetails.id === creator._id
              }
              isAnswered={item.answered}
              showReply
              onClick={() => {
                setShowReplies({
                  chapterId,
                  qna: item as IQNA,
                  creator,
                });
              }}
              onRemoveItem={() => {
                removeQNAItem(item._id);
              }}
            />
          ))}
        </EasyVirtualizedScroller>
      ) : null}
      {footer}
      <AskAQuestion
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        chapterId={chapterId}
        creator={creator}
        onSubmitQuestion={() => loadQNA()}
      />
      {showReplies && (
        <QNAReplies
          showModal={Boolean(showReplies)}
          closeModal={() => setShowReplies(null)}
          {...showReplies}
          onRefresh={() => loadQNA()}
        />
      )}
    </>
  );
};

export default QnASection;
