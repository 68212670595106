import React, {
  useEffect,
  useState,
} from 'react';

import {
  message,
  Typography,
} from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import CourseAPI from '../../api/CourseAPI';
import Loader from '../../components/Loader';
import { useAppNavigate } from '../../shared/hooks';
import { ICourseType } from '../../types/courseTypes';
import { ROUTES } from '../../types/routes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';
import CourseListCard from './CourseListCard/CourseListCard';

interface Props {
  userId: string | null;
}

interface IState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  courses: ICourseType[];
  refreshing: boolean;
}

const SIZE = 100;

const MyCourses: React.FC<Props> = ({ userId }) => {
  const navigate = useAppNavigate();

  const [state, setState] = useState<IState>({
    loading: false,
    page: 1,
    hasMore: true,
    courses: [],
    refreshing: false,
  });

  const loadData = async (
    pageCount: any,
    courseList = state.courses,
    hasMore = state.hasMore,
    loading = state.loading,
    currState = state,
  ) => {
    if (hasMore && !loading && userId) {
      setState({ ...currState, loading: true });
      try {
        const res = await CourseAPI.getMyCourses(userId, pageCount, SIZE);
        let newState = { ...currState, loading: false, refreshing: false };
        if (res.status === 200 && res.data.result) {
          const updatedCourses =
            pageCount > 1
              ? [...courseList, ...res.data.result]
              : [...res.data.result];
          newState = {
            ...newState,
            courses: updatedCourses,
            page: pageCount + 1,
            hasMore: res.data.result.length >= SIZE,
          };
        } else {
          message.error(res.data.message || 'Could not load courses');
          newState = {
            ...newState,
            courses: [...courseList],
            hasMore: false,
          };
        }
        setState({ ...state, ...newState });
      } catch (error: any) {
        if (
          error?.response?.data?.result &&
          typeof error?.response?.data?.result === 'string'
        )
          message.error(error?.response?.data?.result);
        else message.error('Could not load courses');
        setState({ ...state, loading: false, hasMore: false });
      }
    }
  };

  useEffect(() => {
    // const newState = {
    //   courses: [],
    //   hasMore: true,
    //   page: 1,
    //   loading: false,
    //   refreshing: false,
    // };
    setState((prev) => ({
      ...prev,
      courses: [],
      hasMore: true,
      page: 1,
    }));
    // loadData(1, [], true, false, newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className="courses__content">
      <EasyVirtualizedScroller
        useParentScrollElement
        hasMore={state.hasMore}
        onLoadMore={() => loadData(state.page, state.courses)}>
        {state.courses.map((item) => (
          <CourseListCard
            key={item._id}
            onPress={() => {
              navigate(ROUTES.COURSE_OVERVIEW, {
                courseId: item._id,
              });
            }}
            type="my"
            title={item.title}
            coverImage={item.coverImage}
            mangoes={item.mangoArr}
          />
        ))}
      </EasyVirtualizedScroller>
      {state.loading || !userId || state.hasMore ? (
        <Loader
          style={{
            margin: '20px 0',
            width: '100%',
          }}
        />
      ) : state.courses.length === 0 ? (
        <NoDataFound title="Whoops! No courses found!" />
      ) : (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '20px 0',
          }}>
          That’s it! No more courses to show.
        </Typography.Paragraph>
      )}
    </div>
  );
};

export default MyCourses;
