import '../MangoChatInfo/styles.scss';

import React from 'react';

import { message, Modal, Typography } from 'antd';

import ChatAPI from '../../../../api/ChatAPI';
import MuteNotification from '../../../../assets/svg/MuteNotification';
import Header from '../../../../components/Header/Header';
import { updateDMRoomData } from '../../../../context/chatReducer';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { Participant } from '../../../../types/chatModels/MangoRoomListResponse';
import DMChatScreenHeader from '../../components/DMChatScreenHeader/DMChatScreenHeader';
import ChatSettingsItem from '../MangoChatInfo/ChatSettingsItem';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  sender: Participant;
  isBlocked: boolean;
  roomId: string;
  updateBlockedParticipants: (blockedParticipants: string[]) => void;
  isNotificationMuted?: boolean;
  updateNotificationMuted?: (isNotificationMuted: boolean) => void;
}

const DMChatInfo: React.FC<Props> = ({
  showModal,
  closeModal,
  sender,
  isBlocked,
  roomId,
  updateBlockedParticipants,
  isNotificationMuted = false,
  updateNotificationMuted = () => {},
}) => {
  const { _id: senderId } = sender;

  const blockedParticipants = useAppSelector(
    (state) =>
      state.chat.dmRoomList.roomList.find((room) => room._id === roomId)
        ?.blockedParticipants,
  );
  const dmRoomList = useAppSelector((state) => state.chat.dmRoomList);
  const dispatch = useAppDispatch();

  const toggleBlockUser = () => {
    if (isBlocked) {
      // unblock this person
      try {
        ChatAPI.blockOrUnblockRoom(senderId, roomId, 'pull');
      } catch (err) {
        console.log('err', err);
        message.error('Failed to unblock user');
      }
      const newRoomList = dmRoomList.roomList.map((room) => {
        if (room._id === roomId) {
          return {
            ...room,
            blockedParticipants: (blockedParticipants || []).filter(
              (p) => p !== senderId,
            ),
          };
        }
        return room;
      });
      dispatch(
        updateDMRoomData({
          ...dmRoomList,
          roomList: newRoomList,
        }),
      );
      updateBlockedParticipants(
        (blockedParticipants || []).filter((p) => p !== senderId),
      );
    } else {
      // block this person

      // call the api for the same
      try {
        ChatAPI.blockOrUnblockRoom(senderId, roomId, 'push');
      } catch (err) {
        console.log('err', err);
        message.error('Failed to block user');
      }
      const newRoomList = dmRoomList.roomList.map((room) => {
        if (room._id === roomId) {
          if (blockedParticipants)
            return {
              ...room,
              blockedParticipants: [...blockedParticipants, senderId],
            };
          return {
            ...room,
            blockedParticipants: [senderId],
          };
        }
        return room;
      });
      dispatch(
        updateDMRoomData({
          ...dmRoomList,
          roomList: newRoomList,
        }),
      );
      updateBlockedParticipants(
        blockedParticipants ? [...blockedParticipants, senderId] : [senderId],
      );
    }
  };

  const toggleMuteNotification = () => {
    const newValue = !isNotificationMuted;
    try {
      ChatAPI.toggleMuteNotificationInRoom(roomId, newValue);
    } catch (err) {
      console.log('err', err);
      message.error('Failed to toggle notifications mute status');
    }
    const newRoomList = dmRoomList.roomList.map((room) => {
      if (room._id === roomId) {
        return {
          ...room,
          isNotificationMuted: newValue,
        };
      }
      return room;
    });
    dispatch(
      updateDMRoomData({
        ...dmRoomList,
        roomList: newRoomList,
      }),
    );
    updateNotificationMuted(newValue);
  };

  return (
    <Modal
      open={showModal}
      title={<Header title="Chat Info" handleBack={closeModal} />}
      destroyOnClose
      className="chatRoomInfoModal"
      closable={false}
      closeIcon={null}
      footer={null}>
      <div className="chatRoomInfoModal__card">
        <DMChatScreenHeader
          senderId={senderId}
          picUrl={sender.profilePicUrl}
          title={sender.name}
        />
      </div>
      <div className="chatRoomInfoModal__card">
        <Typography.Title level={5} className="chatRoomInfoModal__card__title">
          Chat Settings
        </Typography.Title>
        <ChatSettingsItem
          title="Stop receiving messages"
          value={isBlocked}
          onChange={toggleBlockUser}
        />
        <ChatSettingsItem
          icon={<MuteNotification width={32} height={32} />}
          title="Mute notifications"
          value={isNotificationMuted ?? false}
          onChange={toggleMuteNotification}
        />
      </div>
    </Modal>
  );
};
export default DMChatInfo;
