import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { message, Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import FeedAPI from '../../api/FeedAPI';
import DownloadSVG from '../../assets/svg/DownloadSVG';
import Header from '../../components/Header/Header';
import { onRefresh } from '../../context/activityReducer';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { IWebinar, IZoomRecordingDetails } from '../../types/feedTypes';
import WorkshopFlatlist from './WorkshopFlatlist';

interface Props {}

interface IState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  webinars: IWebinar[];
  refreshing: boolean;
}

export interface RefProps {
  refreshPage: () => void;
}

const CompletedWorkshopsScreen: ForwardRefRenderFunction<RefProps, Props> = (
  {},
  ref,
) => {
  const SIZE = 10;
  const dispatch = useAppDispatch();
  const { selected } = useAppSelector((state) => state.activity);
  const [state, setState] = useState<IState>({
    loading: false,
    page: 1,
    hasMore: true,
    webinars: [],
    refreshing: false,
  });
  const [showDialog, setShowDialog] = useState<{
    show: boolean;
    value: IZoomRecordingDetails[];
  }>({
    show: false,
    value: [],
  });

  const getWebinars = async (
    pageCount: any,
    webinarList = state.webinars,
    hasMore = state.hasMore,
    loading = state.loading,
    currState = state,
  ) => {
    if (hasMore && !loading) {
      setState({ ...currState, loading: true });
      try {
        const res = await FeedAPI.getCreatorWebinars({
          status: 'completed',
          page: pageCount,
          limit: SIZE,
        });
        let newState = { ...currState, loading: false, refreshing: false };
        if (res.result && res.result.videocalls.length) {
          const newVideoCalls = [...res.result.videocalls];
          const updatedWebinars =
            pageCount > 1
              ? [...webinarList, ...newVideoCalls]
              : [...newVideoCalls];
          newState = {
            ...newState,
            webinars: updatedWebinars,
            page: pageCount + 1,
            hasMore: res.result.videocalls.length === SIZE,
          };
          if (newVideoCalls.length === 0) {
            getWebinars(pageCount + 1, updatedWebinars, true, false);
          }
        } else {
          newState = {
            ...newState,
            webinars: [...webinarList],
            hasMore: false,
          };
        }
        setState({ ...state, ...newState });
      } catch (error: any) {
        setState({ ...state, loading: false });
      }
    }
  };

  const refreshPage = () => {
    dispatch(onRefresh());
    const newState = {
      webinars: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: true,
    };
    setState((prev) => ({
      ...prev,
      webinars: [],
      hasMore: true,
      page: 1,
    }));
    if (selected) {
      getWebinars(1, [], true, false, newState);
    }
  };

  useEffect(() => {
    const newState = {
      webinars: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setState((prev) => ({
      ...prev,
      webinars: [],
      hasMore: true,
      page: 1,
    }));
    if (selected) {
      getWebinars(1, [], true, false, newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    refreshPage: () => {
      refreshPage();
    },
  }));

  return (
    <>
      <WorkshopFlatlist
        refreshing={state.refreshing}
        onRefreshed={refreshPage}
        loading={state.loading}
        hasMore={state.hasMore}
        webinarType="completed"
        webinars={state.webinars}
        getMoreWebinars={() => getWebinars(state.page, state.webinars)}
        onMenuPress={(data) => {
          const recordings: IZoomRecordingDetails[] = [
            ...(data?.zoomRecordingDetails || []),
            ...[
              ...(data?.hmsRecordingUrlArr || []),
              ...(data?.zoomRecordingUrlArr || []),
              ...(data?.zoomRecordingUrlS3Arr || []),
            ].map((url, index) => ({
              recordingUrl: url,
              recordingId: index.toString(),
              _id: index.toString(),
              recordingType: `default_view_${index + 1}`,
            })),
          ];
          if (recordings.length > 0) {
            setShowDialog({
              show: true,
              value: recordings,
            });
          } else {
            message.error('No recording available');
          }
        }}
      />
      <BottomSheet
        open={showDialog.show}
        onDismiss={() => setShowDialog({ show: false, value: [] })}
        header={
          <Header canGoBack={false} title="Download Recordings" noBorder />
        }>
        <div style={{ padding: 12 }}>
          {showDialog.value.map((item, index) => (
            <div
              key={item._id}
              className="workshop__download__item"
              style={{
                marginBottom: index === showDialog.value.length - 1 ? 0 : 12,
              }}
              onClick={() => {
                const link = item.recordingUrl;
                if (link) {
                  window.open(link, '_blank');
                }
              }}>
              <Typography.Text>
                {item.recordingType.split('_').join(' ').toTitleCase()}
              </Typography.Text>
              <div className="workshop__download__item--pressable">
                <DownloadSVG />
              </div>
            </div>
          ))}
        </div>
      </BottomSheet>
    </>
  );
};

export default forwardRef(CompletedWorkshopsScreen);
