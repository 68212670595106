/* eslint-disable @typescript-eslint/no-use-before-define */
import './styles.scss';

import React, { useEffect } from 'react';

import { Button, message, Modal, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import ReactLinkify from 'react-linkify';

import CourseAPI from '../../../api/CourseAPI';
import TickSvg from '../../../assets/svg/TickSvg';
import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTheme } from '../../../context/ThemeProvider';
import {
  IAssessmentReview,
  IFetchedQuestion,
} from '../../../types/assessmentTypes';

interface Props {
  showModal: boolean;
  retake?: boolean;
  closeModal: () => void;
  assignment: IAssessmentReview;
  onProgress: (progress: number, duration: number) => void;
}

interface IAnswerType {
  selectedAnswer?: number[] | null;
  textAnswer?: string | null;
  type: 'single_select' | 'text';
}

const Assessment: React.FC<Props> = ({
  showModal,
  retake,
  closeModal,
  assignment,
  onProgress,
}) => {
  const { colors } = useTheme();
  const [textValue, setTextValue] = React.useState<string | null>('');
  const { questions } = assignment;
  const [selectedOptions, setSelectedOptions] = React.useState<IAnswerType[]>(
    [],
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isAnswerSubmitting, setIsAnswerSubmitting] =
    React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [currQuestionIndex, setCurrQuestionIndex] = React.useState<number>(0);
  const [currQuestion, setCurrQuestion] =
    React.useState<IFetchedQuestion | null>(
      questions.length > 0 ? questions[0] : null,
    );
  const [isDialogVisible, setIsDialogVisible] = React.useState<boolean>(false);

  const loadCurrentlySelectedOptions = () => {
    const selOptions: IAnswerType[] = new Array(questions.length).fill([]);
    questions.forEach((question) => {
      const { selectedAnswer, textAnswer, type } = question;
      if (retake) {
        selOptions[question.position || 0] = {
          selectedAnswer: null,
          textAnswer: null,
          type,
        };
      } else {
        selOptions[question.position || 0] = {
          selectedAnswer,
          textAnswer,
          type,
        };
      }
    });
    setSelectedOptions(selOptions);
    startAssessment();
  };

  const setOptionSelected = async (
    optionIndex: number,
    optionValue: IAnswerType,
  ) => {
    if (currQuestion) {
      let newSelOptions = [...selectedOptions];
      newSelOptions[optionIndex] = optionValue;
      setSelectedOptions(newSelOptions);
      try {
        setIsAnswerSubmitting(true);
        const resp = await CourseAPI.setAssignmentUserSubmission(
          currQuestion._id,
          {
            selectedAnswer: optionValue.selectedAnswer,
            textAnswer: optionValue.textAnswer,
          },
        );
        if (resp.status === 200) {
          //
        } else {
          newSelOptions = [...selectedOptions];
          newSelOptions[optionIndex] = {
            selectedAnswer: null,
            textAnswer: null,
            type: currQuestion.type,
          };
          setSelectedOptions(newSelOptions);
          message.error(resp.data.message);
        }
      } catch (err: any) {
        console.log(err?.response);
        newSelOptions = [...selectedOptions];
        newSelOptions[optionIndex] = {
          selectedAnswer: null,
          textAnswer: null,
          type: currQuestion.type,
        };
        setSelectedOptions(newSelOptions);
        message.error('Something went wrong');
      } finally {
        setIsAnswerSubmitting(false);
      }
    }
  };

  const startAssessment = async () => {
    try {
      const resp = await CourseAPI.startAssignment(
        assignment.fetchedAssignment._id,
        retake ?? false,
      );
      if (resp.status === 200) {
        setIsLoading(false);
        setCurrQuestionIndex(0);
      } else {
        message.error(resp.data.message);
      }
    } catch (err) {
      console.log(err);
      message.error('Something went wrong');
    }
  };

  const previous = () => {
    if (currQuestionIndex > 0) {
      setCurrQuestionIndex((prev) => prev - 1);
    }
  };

  const next = () => {
    if (questions && currQuestionIndex < questions.length - 1) {
      setCurrQuestionIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (showModal) loadCurrentlySelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, assignment]);

  useEffect(() => {
    setCurrQuestionIndex(0);
    setCurrQuestion(questions.length > 0 ? questions[0] : null);
  }, [questions]);

  useEffect(() => {
    setTextValue(selectedOptions[currQuestionIndex]?.textAnswer || null);
  }, [currQuestionIndex, selectedOptions]);

  const submitAssessment = async () => {
    try {
      setIsSubmitting(true);
      const resp = await CourseAPI.submitAssignment(
        assignment.fetchedAssignment._id,
      );
      if (resp.status === 200) {
        onProgress(100, 100);
        message.info('Review assignment to view results');
        closeModal();
      } else {
        message.error(resp.data.message);
      }
    } catch (err) {
      console.log(err);
      message.error('Something went wrong');
    } finally {
      setIsSubmitting(false);
    }
  };

  const trySubmitAssessment = () => {
    setIsDialogVisible(true);
  };

  useEffect(() => {
    setCurrQuestion(questions[currQuestionIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currQuestionIndex]);

  return (
    <>
      <Modal
        open={showModal}
        title={
          <Header
            title={assignment.fetchedAssignment.title || 'Assessment'}
            backType="cross"
            handleBack={closeModal}
            actionItems={[
              <HeaderTextAction
                text="Submit"
                loadingText="Submitting"
                loading={isSubmitting}
                onClick={trySubmitAssessment}
              />,
            ]}
          />
        }
        destroyOnClose
        className="assessmentModal"
        closable={false}
        footer={
          !isLoading && !isSubmitting ? (
            <div className="assessmentFooter">
              <Button
                className="siteBtn siteBtnSecondary"
                onClick={previous}
                disabled={currQuestionIndex === 0}>
                Previous
              </Button>
              <Button
                className="siteBtn siteBtnSecondary"
                onClick={next}
                disabled={currQuestionIndex > questions.length - 2}>
                Next
              </Button>
            </div>
          ) : null
        }
        closeIcon={null}>
        {!isLoading && !isSubmitting && currQuestion ? (
          <div className="assessmentModalContent">
            <Typography.Title
              level={4}
              className="assessmentModalContent__title">
              <Typography.Text className="assessmentModalContent__title__text">
                Question{' '}
              </Typography.Text>
              {currQuestionIndex + 1}
              <Typography.Text className="assessmentModalContent__title__subtext">
                {' '}
                /
                <Typography.Text className="assessmentModalContent__title__subtext__inner">
                  {' '}
                  {questions.length}
                </Typography.Text>
              </Typography.Text>
            </Typography.Title>
            <div className="progressLinesContainer">
              {selectedOptions?.map((selOpt, index) => (
                <div
                  key={index}
                  className={`progressLines ${
                    selOpt.selectedAnswer?.length || selOpt.textAnswer?.length
                      ? 'answered'
                      : ''
                  }`}
                />
              ))}
            </div>
            <Typography.Paragraph
              className="assessmentModalContent__question"
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: 'See more',
              }}>
              {<ReactLinkify>{HtmlParser(currQuestion.question)}</ReactLinkify>}
            </Typography.Paragraph>
            <div className="assessmentModalContent__options">
              {currQuestion.type === 'single_select' ? (
                currQuestion.options.map((option, index) => (
                  <Button
                    key={index}
                    className={`assessmentModalContent__options__option
                    ${
                      selectedOptions[currQuestionIndex].selectedAnswer &&
                      selectedOptions[
                        currQuestionIndex
                      ].selectedAnswer?.includes(index)
                        ? 'selected'
                        : ''
                    }
                  `}
                    onClick={() => {
                      setOptionSelected(currQuestionIndex, {
                        selectedAnswer: [index],
                        textAnswer: null,
                        type: 'single_select',
                      });
                    }}>
                    <div className="assessmentModalContent__options__option__text">
                      <ReactLinkify>{HtmlParser(option)}</ReactLinkify>
                    </div>
                    {selectedOptions[currQuestionIndex].selectedAnswer &&
                    selectedOptions[currQuestionIndex].selectedAnswer?.includes(
                      index,
                    ) ? (
                      <TickSvg
                        circleColor={colors.PRIMARY}
                        backgroundColor={colors.BACKGROUND}
                        width={24}
                        height={24}
                      />
                    ) : (
                      <div className="assessmentModalContent__options__option__tick" />
                    )}
                  </Button>
                ))
              ) : (
                <div className="assessmentModalContent__options__text">
                  <Typography.Title
                    level={5}
                    className="assessmentModalContent__options__text__title">
                    Your answer
                  </Typography.Title>
                  <div style={{ marginTop: 6 }}>
                    <textarea
                      className="assessmentModalContent__options__text__input"
                      value={textValue || ''}
                      onChange={(e) => setTextValue(e.target.value)}
                      placeholder="Type your answer here"
                    />
                  </div>
                  <div className="assessmentModalContent__options__text__submit">
                    <Button
                      className="siteBtn siteBtnPrimary"
                      loading={isAnswerSubmitting}
                      disabled={isAnswerSubmitting}
                      onClick={() => {
                        if (!textValue?.length) {
                          message.error('Please enter your answer');
                          return;
                        }
                        setOptionSelected(currQuestionIndex, {
                          selectedAnswer: null,
                          textAnswer: textValue,
                          type: 'text',
                        });
                      }}>
                      Submit Answer
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <Loader
            size="large"
            style={{
              margin: '45px 0',
              width: '100%',
              textAlign: 'center',
            }}
          />
        )}
      </Modal>
      <ConfirmationModal
        open={isDialogVisible}
        message={
          selectedOptions.filter(
            (o) => o.selectedAnswer?.length || o.textAnswer?.length,
          ).length < questions.length
            ? `You have submitted only ${
                selectedOptions.filter(
                  (o) => o.selectedAnswer?.length || o.textAnswer?.length,
                ).length
              } of ${questions.length} questions in this test. `
            : 'You have attempted all questions.'
        }
        title="Are you sure you want to submit the quiz?"
        okayButtonText={
          selectedOptions.filter(
            (o) => o.selectedAnswer?.length || o.textAnswer?.length,
          ).length < questions.length
            ? 'Submit Anyways'
            : 'Submit'
        }
        cancelButtonText="Continue Quiz"
        handleOk={() => {
          submitAssessment();
          setIsDialogVisible(false);
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
    </>
  );
};
export default React.memo(Assessment);
