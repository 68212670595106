import './styles.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import moment from 'moment';
import { IoGlobeOutline } from 'react-icons/io5';
import { MdError } from 'react-icons/md';
import ReactLinkify from 'react-linkify';
import { useParams, useSearchParams } from 'react-router-dom';

import CourseAPI from '../../api/CourseAPI';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import CustomImage from '../../components/CustomImage/CustomImage';
import Header from '../../components/Header/Header';
import Zoom from '../../components/ImageViewer/Zoom';
import Loader from '../../components/Loader';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import BitmovinPlayer from '../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import DripLocked from '../../components/VideoPlayer/DripLocked';
import { updateMedia } from '../../context/mediaReducer';
import { COURSE_NAVIGATOR_HEIGHT } from '../../shared/data';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import { getQueryString, getResourceName } from '../../shared/utils';
import { Chapter, ICourseExtended } from '../../types/courseTypes';
import AssessmentItem from './AssessmentItem/AssesmentItem';
import ChapterDetails from './ChapterDetails/ChapterDetails';
import CourseNavigator from './CourseNavigator';
import ResourceItem from './ResourceItem/ResourceItem';

const CourseDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams();
  const navigate = useAppNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const chapterId = searchParams.get('chapter');
  // const { drmType } = checkBrowser();
  const { isDRMUser, deeplinkUrl } = useAppSelector((state) => state.app);

  const [course, setCourse] = useState<ICourseExtended | null>(null);
  const [chaptersProgress, setChaptersProgress] = React.useState<
    Record<string, number>
  >({});
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [loading, setLoading] = useState(true);
  const [chapterIndex, setChapterIndex] = React.useState<number>(0);
  const [selectedChapterId, setSelectedChapterId] = React.useState<
    string | undefined
  >(chapterId || undefined);
  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null);
  const [showPdfViewer, setShowPdfViewer] = useState(false);

  useEffect(() => {
    if (selectedChapter && selectedChapter._id) {
      const params = getQueryString({
        chapter: selectedChapter._id,
      });
      setSearchParams(params, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChapter?._id]);

  const fetchCourse = useCallback(async () => {
    if (!courseId) return;
    setLoading(true);
    Promise.all([
      CourseAPI.getCourseById(courseId),
      CourseAPI.getCourseModules(courseId),
    ])
      .then((res) => {
        setCourse({
          ...res[0].data.result,
          modules: res[1].data.result.mappedResponse || [],
          paidCourse: res[1].data.result.paidCourse,
        });
      })
      .catch(() => {
        message.error('Course not found');
        navigate.goBack();
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    if (course) {
      const chap: Chapter[] = [];
      course.modules?.forEach((module) => {
        module.chapters?.forEach((chapter) => {
          chap.push({
            ...chapter,
            isLocked: module.isLocked,
          });
        });
      });
      setChapters(chap);
    }
  }, [course]);

  useEffect(() => {
    if (!selectedChapter || !course) {
      return;
    }
    course.modules?.forEach((module) => {
      module.chapters?.forEach((chapter, index) => {
        if (chapter._id === selectedChapter._id) {
          setChapterIndex(index);
        }
      });
    });
  }, [selectedChapter, course]);

  useEffect(() => {
    if (selectedChapter) {
      setSelectedChapterId(selectedChapter._id);
    }
  }, [selectedChapter]);

  useEffect(() => {
    if (chapters.length === 0) {
      return;
    }
    if (selectedChapterId) {
      setSelectedChapter(
        chapters.find((chapter) => chapter._id === selectedChapterId) || null,
      );
    } else {
      setSelectedChapter(chapters[0]);
    }
    setChaptersProgress(
      chapters.reduce(
        (acc, current) => {
          acc[current._id] = current.progress || 0;
          return acc;
        },
        {} as Record<string, number>,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapters]);

  useEffect(() => {
    if (
      selectedChapter &&
      ['audio', 'video'].includes(selectedChapter.contentType)
    )
      dispatch(
        updateMedia({
          mediaId: `${selectedChapter._id}`,
          mediaType: selectedChapter.contentType as 'audio' | 'video',
          playerState: 'playing',
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChapter]);

  useEffect(() => {
    if (!isDRMUser) fetchCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, isDRMUser]);

  const isPreviousChapterAvailable = useMemo(() => {
    if (!selectedChapter) {
      return false;
    }
    const index = chapters.findIndex(
      (item) => item._id === selectedChapter._id,
    );
    if (index > 0) {
      return true;
    }
    return false;
  }, [selectedChapter, chapters]);

  const isNextChapterAvailable = useMemo(() => {
    if (!selectedChapter) {
      return false;
    }
    const index = chapters.findIndex(
      (item) => item._id === selectedChapter._id,
    );
    if (index < chapters.length - 1) {
      return true;
    }
    return false;
  }, [selectedChapter, chapters]);

  const onPreviousClick = useCallback(async () => {
    if (!selectedChapter) {
      return;
    }
    const index = chapters.findIndex(
      (item) => item._id === selectedChapter._id,
    );
    setSelectedChapter(null);
    if (index > 0) {
      setSelectedChapter(chapters[index - 1]);
    } else {
      setSelectedChapter(chapters[chapters.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapters, selectedChapter]);

  const onNextClick = useCallback(async () => {
    if (!selectedChapter) {
      return;
    }
    setSelectedChapter(null);
    const index = chapters.findIndex(
      (item) => item._id === selectedChapter._id,
    );
    if (index < chapters.length - 1) {
      setSelectedChapter(chapters[index + 1]);
    } else {
      setSelectedChapter(chapters[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapters, selectedChapter]);

  const getSelectedChapter = useMemo(() => {
    const calculateOverallProgress = (
      currentChapter: string,
      currentChapterProgress: number,
    ) => {
      let completedProgress = 0;
      const totalChapters =
        course?.modules.reduce(
          (acc, currentModule) => acc + currentModule.chapters.length,
          0,
        ) || 1;
      Object.keys(chaptersProgress).forEach((key) => {
        if (key === currentChapter) {
          completedProgress += currentChapterProgress || 0;
        } else {
          completedProgress += chaptersProgress[key] || 0;
        }
      });
      return parseInt((completedProgress / totalChapters).toString(), 10);
    };

    const calculateModuleProgress = (
      currentChapter: string,
      currentChapterProgress: number,
    ) => {
      let completedProgress = 0;
      const module = course?.modules.find((m) =>
        m.chapters.find((c) => c._id === currentChapter),
      );
      const totalChapters = module?.chapters.length || 1;
      module?.chapters.forEach((c) => {
        if (currentChapter === c._id) {
          completedProgress += currentChapterProgress || 0;
        } else {
          completedProgress += chaptersProgress[c._id] || 0;
        }
      });
      return {
        module: module?._id || '',
        moduleProgress: parseInt(
          (completedProgress / totalChapters).toString(),
          10,
        ),
      };
    };

    const updateProgressToServer = (
      currentChapter: string,
      progress: number,
    ) => {
      if (!course) return;
      const nextChapter =
        chapters.findIndex?.((c) => c._id === currentChapter) + 1;
      let overallProgress: number = calculateOverallProgress(
        currentChapter,
        progress,
      );
      const { module, moduleProgress } = calculateModuleProgress(
        currentChapter,
        progress,
      );
      if (
        moduleProgress > 98 &&
        !loading &&
        course.isDripped &&
        course.dripType === 'by_completion'
      ) {
        fetchCourse();
      }
      if (nextChapter >= chapters.length) {
        CourseAPI.updateChapterProgress(
          currentChapter,
          progress,
          null,
          course._id,
          module,
          overallProgress,
          moduleProgress,
        );
      } else {
        CourseAPI.updateChapterProgress(
          currentChapter,
          progress,
          chapters[nextChapter]._id,
          course._id,
          module,
          overallProgress,
          moduleProgress,
        );
      }
    };

    const updateProgress = (currentChapter: string, progress: number) => {
      // call progress api
      if (course) {
        setChaptersProgress((prev) => ({
          ...prev,
          [currentChapter]: progress,
        }));
        updateProgressToServer(currentChapter, progress);
      }
    };

    if (!selectedChapter) {
      return null;
    }

    if (!selectedChapter.contentType) return null;
    const onProgress = (curProgress: number, duration: number) => {
      const progress = (curProgress / duration) * 100;
      if (
        selectedChapter &&
        Math.ceil(progress) >
          Math.ceil(chaptersProgress[selectedChapter._id] || 0)
      ) {
        updateProgress(selectedChapter._id, Math.ceil(progress));
      }
    };
    const onEnded = () => {
      onProgress(1, 1);
      onNextClick();
    };
    switch (selectedChapter.contentType.split('/')[0].toLocaleLowerCase()) {
      case 'assignment':
        return (
          <AssessmentItem
            chapterId={selectedChapter.assignment || ''}
            goToNextChapter={() => {
              onNextClick();
            }}
            onProgress={onProgress}
          />
        );
      case 'image':
        const url = selectedChapter.content || '';
        return (
          <div className="courseMediaWrapper">
            <div className="courseImageWrapper">
              <Zoom src={url}>
                <CustomImage
                  onLoad={() => {
                    onProgress(1, 1);
                  }}
                  src={url}
                  alt=""
                  className="mainImage"
                />
              </Zoom>
              <div className="blurImg">
                <CustomImage src={url} alt="" />
              </div>
            </div>
          </div>
        );
      case 'video':
        return (
          <div className="courseMediaWrapper">
            <div className="courseVideoWrapper">
              <BitmovinPlayer
                mediaId={selectedChapter._id}
                isCompressDone={Boolean(selectedChapter.isCompressDone)}
                isCompressedUrl={Boolean(selectedChapter.compressedVideoUrl)}
                mediaUrl={selectedChapter.content}
                compressedUrl={selectedChapter.compressedVideoUrl}
                key={selectedChapter._id}
                dashUrl={selectedChapter.compressedVideoUrlDash}
                hlsUrl={selectedChapter.compressedVideoUrlHls}
                hasError={selectedChapter.isCompressError}
                errorDescription={selectedChapter.compressErrorDescription}
                isDRMEnabled={selectedChapter.isDrmEnabled}
                contentId={selectedChapter.qencodePostId}
                initalProgress={chaptersProgress[selectedChapter._id] || 0}
                onEnded={onEnded}
                onProgress={onProgress}
                mediaThumb={selectedChapter.thumbnail}
                subtitleTracks={selectedChapter.subtitles}
              />
            </div>
          </div>
        );
      case 'audio':
        return (
          <div className="courseMediaWrapper">
            <AudioPlayer
              url={selectedChapter.content || ''}
              id={selectedChapter._id}
              onProgress={onProgress}
              onEnded={onEnded}
              initalProgress={chaptersProgress[selectedChapter._id] || 0}
            />
          </div>
        );
      case 'article':
        return (
          <div className="article-content">
            <div
              className="slimScroll"
              onLoad={() => {
                onProgress(1, 1);
              }}>
              <ReactLinkify>
                {HtmlParser(selectedChapter.content || '')}
              </ReactLinkify>
            </div>
          </div>
        );
      case 'iframe':
        return (
          <DripLocked
            icon={
              <IoGlobeOutline
                size={40}
                color="#fff"
                style={{
                  marginBottom: 12,
                }}
              />
            }
            title="This chapter is an external link"
            button={{
              text: 'Open in new tab',
              onClick: () => {
                onProgress(1, 1);
                window.open(selectedChapter.content, '_blank');
              },
            }}
          />
        );
      case 'pdf':
        return selectedChapter.content ? (
          <>
            <div style={{ padding: 12 }}>
              <ResourceItem
                url={selectedChapter.content}
                name={getResourceName(selectedChapter.content)}
                onClick={() => {
                  setShowPdfViewer(true);
                }}
              />
            </div>
            <PdfViewer
              showModal={showPdfViewer}
              closeModal={() => {
                setShowPdfViewer(false);
              }}
              url={selectedChapter?.content || ''}
              initalProgress={selectedChapter?.progress || 0}
              onProgress={onProgress}
              title={selectedChapter?.title || ''}
            />
          </>
        ) : null;
    }
  }, [
    selectedChapter,
    course,
    chaptersProgress,
    chapters,
    loading,
    fetchCourse,
    onNextClick,
    showPdfViewer,
  ]);

  const dripMessage = useMemo(() => {
    if (selectedChapter && course && course.isDripped && course.dripType) {
      const { dripType, firstSubscriptionDate } = course;
      const module = course.modules.find((item) =>
        item.chapters.findIndex(
          (chapter) => chapter._id === selectedChapter._id,
        ) > -1
          ? true
          : false,
      );
      if (!module) {
        return '';
      }
      const { dripOpenByDate, dripOpenByDaysAfterEnrollment } = module;
      switch (dripType) {
        case 'by_completion':
          return 'This chapter will be unlocked after you complete the previous chapter. If not updated, go back and try again!';
        case 'by_date':
          if (dripOpenByDate) {
            return `This chapter will be unlocked on ${moment(
              dripOpenByDate,
            ).format('DD MMM YYYY')}`;
          } else {
            return '';
          }
        case 'by_enrollment':
          if (dripOpenByDaysAfterEnrollment && firstSubscriptionDate) {
            const date = moment(firstSubscriptionDate).add(
              dripOpenByDaysAfterEnrollment,
              'days',
            );
            return `This chapter will be unlocked on ${date.format(
              'DD MMM YYYY',
            )}`;
          } else {
            return '';
          }
      }
    }
    return '';
  }, [course, selectedChapter]);

  if (!courseId) {
    navigate.goBack();
    return null;
  }

  return (
    <>
      <div className="pageRoot">
        <Header title={course?.title || ''} />
        <div
          className="pageContent"
          data-body-scroll-lock-ignore="true"
          style={{
            paddingBottom: COURSE_NAVIGATOR_HEIGHT + 28,
          }}>
          {course && !loading ? (
            <div className="courseDetails__wrapper">
              {course.paidCourse &&
              selectedChapter &&
              !selectedChapter.isFreeChapter ? (
                <DripLocked
                  button={{
                    text: 'Buy now to unlock',
                    onClick: () => {
                      if (course.mangoTosell) {
                        const url = `${deeplinkUrl}web/checkout/${course.mangoTosell}`;
                        window.open(url, '_blank');
                      } else {
                        message.error('Course not available for purchase!');
                      }
                    },
                  }}
                />
              ) : !selectedChapter || selectedChapter.isLocked ? (
                <DripLocked message={dripMessage} />
              ) : (
                getSelectedChapter
              )}
              {selectedChapter !== null && (
                <ChapterDetails
                  chapter={selectedChapter}
                  course={course}
                  chapterIndex={chapterIndex}
                />
              )}
            </div>
          ) : isDRMUser ? (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                margin: '45px 0',
                padding: '0 12px',
              }}>
              <MdError size={40} />
              <Typography.Paragraph
                style={{
                  fontWeight: '600',
                  marginTop: '20px',
                }}>
                Use mobile app to view this course.
              </Typography.Paragraph>
            </div>
          ) : (
            <Loader
              size="large"
              style={{
                width: '100%',
                textAlign: 'center',
                margin: '45px 0',
              }}
            />
          )}
        </div>
        {course !== null && (
          <CourseNavigator
            isPreviousChapterAvailable={isPreviousChapterAvailable}
            isNextChapterAvailable={isNextChapterAvailable}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        )}
      </div>
    </>
  );
};
export default CourseDetails;
