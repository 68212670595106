/* eslint-disable @typescript-eslint/naming-convention */
import './styles.scss';

import React, { useCallback, useMemo, useState } from 'react';

import { message, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import { IoChevronDown } from 'react-icons/io5';

import UserAPI from '../../../../api/UserAPI';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { ISubscription } from '../../../../types/userTypes';
import NoDataFound from '../../../Feed/components/NoDataFound/NoDataFound';
import Subscription from '../../components/Subscription';

const title = 'Subscriptions';

type TSUB_TYPES = 'Active' | 'Expired';
const SUB_TYPES: TSUB_TYPES[] = ['Active', 'Expired'];

const Subscriptions: React.FC = () => {
  const { colors } = useTheme();

  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [selectedSubType, setSelectedSubType] = useState<TSUB_TYPES>('Active');
  const [loading, setLoading] = React.useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      const resp = await UserAPI.getAllSubscriptions();
      if (resp.status !== 200) {
        message.error(resp.data.message);
      } else {
        setSubscriptions(resp.data.result);
      }
    } catch (err) {
      message.error('Error fetching subscriptions!!');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagItem = useCallback(
    (
      value: string,
      key: string,
      onClick: () => void,
      isSelected: boolean,
      hasArrow: boolean = false,
    ) => (
      <div
        key={key}
        onClick={onClick}
        className={`tags-filter__tag ${isSelected ? 'active' : ''}`}>
        <Typography.Text>{value}</Typography.Text>
        {hasArrow && (
          <IoChevronDown
            size={18}
            color={isSelected ? colors.PRIMARY : colors.ICON}
          />
        )}
      </div>
    ),
    [colors.ICON, colors.PRIMARY],
  );

  const filteredSubscriptions = useMemo(() => {
    if (selectedSubType === 'Active') {
      return subscriptions.filter(
        (subscription) =>
          subscription.status === 'active' ||
          subscription.status === 'manualactive',
      );
    } else {
      return subscriptions.filter(
        (subscription) =>
          subscription.status === 'expired' ||
          subscription.status === 'manualexpired',
      );
    }
  }, [selectedSubType, subscriptions]);

  return (
    <div className="pageRoot">
      <Header title={title} />
      <div className="tags-filter__container" style={{ borderTop: 'none' }}>
        <div className="tags-filter__wrapper">
          {SUB_TYPES.map((subType, index) =>
            getTagItem(
              subType,
              subType + index,
              () => setSelectedSubType(subType),
              subType === selectedSubType,
            ),
          )}
        </div>
      </div>
      <div className="pageContent subscriptionList pageContentList">
        {filteredSubscriptions.length > 0 ? (
          <EasyVirtualizedScroller hasMore={false} useParentScrollElement>
            {filteredSubscriptions.map((item: ISubscription) => (
              <Subscription
                key={item._id}
                subscription={item}
                reloadSubscriptions={() => {
                  setSubscriptions([]);
                  loadData();
                }}
              />
            ))}
          </EasyVirtualizedScroller>
        ) : (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '45px 0',
            }}>
            {loading ? (
              <Loader size="large" />
            ) : (
              <NoDataFound title="No subscriptions found!" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
