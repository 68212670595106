import React from 'react';

import { Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import { MdCheck, MdClose } from 'react-icons/md';

import { useTheme } from '../../../../context/ThemeProvider';
import { IFetchedQuestionWithSubmissions } from '../../../../types/assessmentTypes';

interface Props {
  question: IFetchedQuestionWithSubmissions;
}

const ReviewAnswersheetItem: React.FC<Props> = ({ question }) => {
  const { colors } = useTheme();

  const generateOptions = () => {
    if (question.type !== 'text' && question.options) {
      const options: JSX.Element[] = [];
      question.options.forEach((option, index) => {
        let color = colors.SECONDARY;
        if (question.answer && question.answer.includes(index)) {
          color = colors.GREEN;
        } else if (
          question.selectedAnswer &&
          question.selectedAnswer.includes(index)
        ) {
          color = colors.DANGER;
        }
        options.push(
          <div
            key={option}
            className="review-answersheet-item__answer__option"
            style={{
              backgroundColor: `${color}50`,
              borderColor: color,
            }}>
            {HtmlParser(option)}
          </div>,
        );
      });
      return options;
    }
    return null;
  };

  return (
    <div className="review-answersheet-item">
      <div className="review-answersheet-item__number">
        <Typography.Text className="review-answersheet-item__number__text">
          {question.position + 1}
        </Typography.Text>
      </div>
      <div
        style={{
          width: '100%',
          marginLeft: 12,
        }}>
        <Typography.Title
          level={4}
          className="review-answersheet-item__question-title"
          ellipsis={{
            rows: 3,
            expandable: true,
            symbol: 'See more',
          }}>
          {HtmlParser(question.question)}
        </Typography.Title>
        <div className="review-answersheet-item__answer-wrapper">
          {question.selectedAnswer || question.textAnswer ? (
            <div className="review-answersheet-item__answer-wrapper__inner">
              <div className="review-answersheet-item__answer">
                <Typography.Title
                  level={5}
                  className="review-answersheet-item__answer__title">
                  {question.type === 'text' ? 'Your answer' : 'Options'}
                </Typography.Title>
                <div style={{ marginTop: 6 }}>
                  {question.type === 'text' ? (
                    <Typography.Paragraph
                      className="review-answersheet-item__answer__text"
                      ellipsis={{
                        rows: 6,
                        expandable: true,
                        symbol: 'See more',
                      }}>
                      {question.textAnswer || ''}
                    </Typography.Paragraph>
                  ) : (
                    generateOptions()
                  )}
                </div>
              </div>
              <div className="review-answersheet-item__marks__wrapper">
                {question.type === 'text' ? (
                  question.marks ? (
                    <div className="review-answersheet-item__marks__question-marks">
                      <Typography.Text className="status__text">
                        Answer Quality
                      </Typography.Text>
                      <div className="review-answersheet-item__marks__question-marks__marks">
                        {[1, 2, 3, 4, 5].map((item, index) => (
                          <div
                            key={item}
                            className="review-answersheet-item__marks__question-marks__marks__mark"
                            style={
                              (question.marks || 0) > index
                                ? {
                                    backgroundColor: `${colors.BLUE}20`,
                                    borderColor: colors.BLUE,
                                  }
                                : {
                                    backgroundColor: `${colors.SECONDARY}20`,
                                    borderColor: colors.SECONDARY,
                                  }
                            }>
                            <Typography.Text
                              className="status__text"
                              style={{
                                color:
                                  (question.marks || 0) > index
                                    ? colors.BLUE
                                    : colors.TEXT1,
                              }}>
                              {item}
                            </Typography.Text>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Typography.Text className="status__text">
                      Not yet reviewed
                    </Typography.Text>
                  )
                ) : (
                  <div className="review-answersheet-item__marks__question-status">
                    <div
                      className="review-answersheet-item__marks__question-status__icon"
                      style={{
                        backgroundColor: question.correct
                          ? colors.GREEN
                          : colors.DANGER,
                      }}>
                      {question.correct ? (
                        <MdCheck color={colors.BACKGROUND} />
                      ) : (
                        <MdClose color={colors.BACKGROUND} />
                      )}
                    </div>
                    <Typography.Text className="status__text">
                      {question.correct ? 'Correct' : 'Wrong'} Answer
                    </Typography.Text>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="review-answersheet-item__not-answered">
              <Typography.Text className="status__text">
                Not Answered
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReviewAnswersheetItem);
