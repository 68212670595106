import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Button, message, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import moment from 'moment';
import {
  IoLockClosed,
  IoStar,
  IoStarOutline,
  IoThumbsUp,
} from 'react-icons/io5';
import ReactLinkify from 'react-linkify';
import { useParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import CourseAPI from '../../api/CourseAPI';
import ArrowSvg from '../../assets/svg/ArrowSvg';
import CustomImage from '../../components/CustomImage/CustomImage';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import MenuButton from '../../components/MenuButton/MenuButton';
import { useTheme } from '../../context/ThemeProvider';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import {
  createTag,
  durationFormat,
  shadeColor,
  tintColor,
} from '../../shared/utils';
import { ICourseExtended } from '../../types/courseTypes';
import { ROUTES } from '../../types/routes';
import RatingModal from './RatingModal/RatingModal';
import SectionModule from './SectionItem/SectionModule';

const CourseOverview: React.FC = () => {
  const navigate = useAppNavigate();

  const { courseId } = useParams();

  const { colors } = useTheme();

  const { id } = useAppSelector((state) => state.user);
  const { isLoggedIn, deeplinkUrl } = useAppSelector((state) => state.app);

  const [course, setCourse] = useState<ICourseExtended | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [courseRating, setCourseRating] = useState<number>(0);
  const [courseReview, setCourseReview] = useState<string>('');

  const isMe = useAppSelector(
    (state) =>
      state.user.id === course?.creator?._id ||
      state.user.type === 'creator_restricted',
  );

  const progress = useMemo(
    () =>
      (course?.modules || []).reduce(
        (acc, curr) => {
          const totalDuration = curr.chapters.reduce(
            (prev, chap) => prev + (chap.totalDuration || 0),
            0,
          );
          const completed = curr.chapters.reduce(
            (prev, chap) => prev + ((chap.progress || 0) >= 95 ? 1 : 0),
            0,
          );

          const completedDuration = curr.chapters.reduce(
            (prev, chap) =>
              prev + (chap.progress || 0) * ((chap.totalDuration || 0) / 100),
            0,
          );

          const totalAssessments = curr.chapters.reduce(
            (prev, chap) => prev + (chap?.contentType === 'assignment' ? 1 : 0),
            0,
          );

          const totalResources = curr.chapters.reduce(
            (prev, chap) =>
              prev +
              (chap?.additionalContent ? chap.additionalContent.length : 0),

            0,
          );

          acc.totalDuration += totalDuration || 0;
          acc.completedDuration += completedDuration || 0;
          acc.completed += completed || 0;
          acc.chapters += curr.chapters.length || 0;
          acc.totalAssessments += totalAssessments || 0;
          acc.totalResources += totalResources || 0;
          if (course) {
            acc.completedPercentage =
              typeof course.progress === 'number'
                ? course.progress
                : course.progress?.progress || 0;
          } else {
            acc.completedPercentage = 0;
          }
          return acc;
        },
        {
          completed: 0,
          totalDuration: 0,
          chapters: 0,
          completedDuration: 0,
          completedPercentage: 0,
          totalAssessments: 0,
          totalResources: 0,
        },
      ),
    [course],
  );

  const getCertificateMail = async () => {
    if (
      course &&
      typeof course?.progress !== 'number' &&
      course?.progress._id
    ) {
      const m = message.loading('Sending certificate to your email...');
      try {
        const res = await CourseAPI.sendCertificateMail(
          course._id,
          course.progress._id,
        );
        m();
        if (res.status === 200) {
          if (res.data.result === 'nocertificate')
            message.warning('Cerificate is not published');
          else message.success('Certificate is sent to your email!');
        }
      } catch (err) {
        m();
        message.error('Something went wrong');
        console.log(err);
      }
    } else {
      message.error('Certificate is not available!');
    }
  };

  const fetchCourse = async () => {
    if (!courseId) return;
    setLoading(true);
    try {
      const res = await CourseAPI.getCourseById(courseId);
      let res2 = null;
      if (isLoggedIn) {
        res2 = await CourseAPI.getCourseModules(courseId);
      }
      setCourse({
        ...res.data.result,
        paidCourse: res2?.data.result.paidCourse ?? false,
        modules: res2?.data.result.mappedResponse || [],
      });
    } catch (error) {
      message.error('Course not found');
      navigate.goBack();
    } finally {
      setLoading(false);
    }
  };

  const fetchCourseRating = async () => {
    if (!course?._id) return;
    try {
      const res = await CourseAPI.getCourseReview(course?._id);
      if (res.status === 200 && res.data.result) {
        setCourseRating(res.data.result.rating);
        setCourseReview(res.data.result.review || '');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (course?._id) {
      fetchCourseRating();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course?._id]);

  useEffect(() => {
    fetchCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  if (!courseId) {
    navigate.goBack();
    return null;
  }

  return (
    <>
      <div className="pageRoot">
        <Header
          title="Course Overview"
          actionItems={[
            course ? (
              <MenuButton
                onClick={() => {
                  setShowDescriptionModal(true);
                }}
              />
            ) : undefined,
          ]}
        />
        {course && !loading ? (
          <div className="pageContent courseOverview">
            <div className="courseOverview__card__wrapper">
              <div
                className="courseOverview__cover"
                style={{
                  backgroundImage: `url(${
                    course.coverImage ||
                    'https://tagmango.com/staticassets/-best-tools-to-create-animated-videos-d1f0d8129db439edcce5b40b12666d15.webp'
                  })`,
                }}>
                <CustomImage
                  className="courseOverview__coverImage"
                  src={
                    course.coverImage ||
                    'https://tagmango.com/staticassets/-best-tools-to-create-animated-videos-d1f0d8129db439edcce5b40b12666d15.webp'
                  }
                  fallbackSrc="https://tagmango.com/staticassets/-best-tools-to-create-animated-videos-d1f0d8129db439edcce5b40b12666d15.webp"
                  onError={(e) => {
                    if (e.currentTarget.parentElement)
                      e.currentTarget.parentElement.style.backgroundImage = `url(https://tagmango.com/staticassets/-best-tools-to-create-animated-videos-d1f0d8129db439edcce5b40b12666d15.webp)`;
                  }}
                />
                {course.paidCourse ? (
                  <div className="courseOverview__coverImage__overlay">
                    <div className="courseOverview__coverImage__overlay__icon">
                      <IoLockClosed />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="courseOverview__card titleCard">
                <Typography.Title
                  ellipsis={{ rows: 2, expandable: false }}
                  level={5}
                  className="courseOverview__title">
                  {course.title || ''}
                </Typography.Title>
                {course.expiry &&
                moment(course.expiry).diff(moment(), 'days') > 0 &&
                moment(course.expiry).diff(moment(), 'days') < 365
                  ? createTag(
                      `EXPIRES IN ${moment(course.expiry).diff(
                        moment(),
                        'days',
                      )} DAYS`,
                      'danger',
                      colors,
                    )
                  : null}
              </div>
              {isLoggedIn ? (
                course.paidCourse && !isMe ? (
                  <div className="courseOverview__card progressCard">
                    <div className="progressCard__inner">
                      <Button
                        className="siteBtn siteBtnPrimary"
                        onClick={() => {
                          if (course.mangoTosell) {
                            const url = `${deeplinkUrl}web/checkout/${course.mangoTosell}`;
                            window.open(url, '_blank');
                          } else {
                            message.error('Course not available for purchase!');
                          }
                        }}>
                        <Typography.Text>Buy now to unlock</Typography.Text>
                        <ArrowSvg />
                      </Button>
                    </div>
                  </div>
                ) : isMe || progress.completedPercentage === 0 ? (
                  <div className="courseOverview__card progressCard">
                    <div className="progressCard__inner">
                      <Button
                        className="siteBtn siteBtnPrimary"
                        onClick={() => {
                          navigate(ROUTES.COURSE_DETAILS, {
                            courseId,
                          });
                        }}>
                        <Typography.Text>
                          {isMe ? 'Preview' : 'Start Learning'}
                        </Typography.Text>
                        <ArrowSvg />
                      </Button>
                    </div>
                  </div>
                ) : progress.completedPercentage > 0 &&
                  progress.completedPercentage < 95 ? (
                  <div className="courseOverview__card progressCard">
                    <div className="progressCard__inner">
                      {id !== course.creator._id && (
                        <div className="progressCard__contents">
                          <Typography.Paragraph className="progressCard__innerText__light">
                            {`Overall progress ${(
                              progress.completedPercentage || 0
                            ).toFixed(0)}%`}
                          </Typography.Paragraph>
                          <div className="progressbar">
                            <div
                              className="progressbar__inner"
                              style={{
                                width: `${progress.completedPercentage || 0}%`,
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <Button
                        className="siteBtn siteBtnPrimary"
                        onClick={() => {
                          navigate(ROUTES.COURSE_DETAILS, {
                            courseId,
                            chapter:
                              typeof course.progress === 'number'
                                ? undefined
                                : course.progress?.currentChapterProgress ===
                                    100
                                  ? course.progress?.nextChapter
                                  : course.progress?.currentChapter,
                          });
                        }}>
                        <Typography.Text>Continue</Typography.Text>
                        <ArrowSvg />
                      </Button>
                    </div>
                  </div>
                ) : progress.completedPercentage >= 95 ? (
                  <div className="courseOverview__card courseOverview__completedcard__container">
                    <div
                      className="courseOverview__completedcard__certificate"
                      onClick={
                        course.certificateId ? getCertificateMail : () => {}
                      }
                      style={{
                        backgroundColor: tintColor(colors.WARNING, 85),
                      }}>
                      <div className="courseOverview__completedcard__certificate__icon">
                        <IoThumbsUp size={24} color={colors.BACKGROUND} />
                      </div>
                      <div className="courseOverview__completedcard__certificate__text">
                        <Typography.Title
                          level={4}
                          className="courseOverview__completedcard__certificate__title">
                          <b>Congrats!</b> you've completed the course.
                        </Typography.Title>
                        {course.certificateId ? (
                          <Typography.Title
                            level={4}
                            className="courseOverview__completedcard__certificate__subTitle"
                            style={{
                              color: shadeColor(colors.WARNING, 20),
                            }}>
                            Download certificate
                          </Typography.Title>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="courseOverview__completedcard__rate-course"
                      onClick={() => {
                        setShowRatingModal(true);
                      }}
                      style={{
                        backgroundColor: tintColor(colors.WARNING, 85),
                      }}>
                      <div className="courseOverview__completedcard__rate-course__star__wrapper">
                        {[1, 2, 3, 4, 5].map((_, index) => (
                          <div
                            key={index}
                            className="courseOverview__completedcard__rate-course__star">
                            {index < courseRating ? (
                              <IoStar size={20} color={colors.WARNING} />
                            ) : (
                              <IoStarOutline size={20} color={colors.ICON} />
                            )}
                          </div>
                        ))}
                      </div>
                      <Typography.Text className="courseOverview__completedcard__rate-course__text">
                        {courseRating > 0 ? 'Edit review' : 'Rate this course'}
                      </Typography.Text>
                    </div>
                  </div>
                ) : null
              ) : (
                <div className="courseOverview__card progressCard">
                  <div className="progressCard__inner">
                    <Button
                      className="siteBtn siteBtnPrimary"
                      onClick={() => {
                        navigate(ROUTES.LOGIN, undefined, {
                          state: {
                            redirectTo: ROUTES.COURSE_OVERVIEW.replace(
                              ':courseId',
                              courseId,
                            ),
                          },
                        });
                      }}>
                      <Typography.Text>Login</Typography.Text>
                      <ArrowSvg />
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {course.modules?.length > 0
              ? course.modules?.map((module, index) => (
                  <SectionModule
                    key={module._id}
                    index={index + 1}
                    title={module.title || ''}
                    chapters={module.chapters || []}
                    isLocked={module.isLocked}
                    showProgress={!isMe && isLoggedIn}
                    onChapterClick={(chapter) => {
                      navigate(ROUTES.COURSE_DETAILS, {
                        courseId,
                        chapter,
                      });
                    }}
                    purchaseRequired={course.paidCourse}
                  />
                ))
              : null}
          </div>
        ) : (
          <Loader
            size="large"
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '45px 0',
            }}
          />
        )}
      </div>
      <BottomSheet
        open={showDescriptionModal}
        onDismiss={() => setShowDescriptionModal(false)}
        header={
          <Header canGoBack={false} title="About this course" noBorder />
        }>
        {course ? (
          <div className="courseOverview">
            <div className="courseOverview__card">
              {isLoggedIn ? (
                <div className="courseOverview__description__list">
                  <div className="courseOverview__description__list__item">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21 3H3C1.89 3 1 3.89 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H8V21H16V19H21C21.5304 19 22.0391 18.7893 22.4142 18.4142C22.7893 18.0391 23 17.5304 23 17V5C23 3.89 22.1 3 21 3ZM21 17H3V5H21M16 11L9 15V7"
                        fill={colors.ICON}
                      />
                    </svg>
                    <Typography.Text>
                      {durationFormat(progress.totalDuration || 0)} of content
                    </Typography.Text>
                  </div>
                  <div className="courseOverview__description__list__item">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19 3.07263H14.82C14.4 1.91263 13.3 1.07263 12 1.07263C10.7 1.07263 9.6 1.91263 9.18 3.07263H5C4.46957 3.07263 3.96086 3.28335 3.58579 3.65842C3.21071 4.03349 3 4.5422 3 5.07263V19.0726C3 19.6031 3.21071 20.1118 3.58579 20.4868C3.96086 20.8619 4.46957 21.0726 5 21.0726H19C19.5304 21.0726 20.0391 20.8619 20.4142 20.4868C20.7893 20.1118 21 19.6031 21 19.0726V5.07263C21 4.5422 20.7893 4.03349 20.4142 3.65842C20.0391 3.28335 19.5304 3.07263 19 3.07263ZM12 3.07263C12.2652 3.07263 12.5196 3.17799 12.7071 3.36553C12.8946 3.55306 13 3.80742 13 4.07263C13 4.33785 12.8946 4.5922 12.7071 4.77974C12.5196 4.96727 12.2652 5.07263 12 5.07263C11.7348 5.07263 11.4804 4.96727 11.2929 4.77974C11.1054 4.5922 11 4.33785 11 4.07263C11 3.80742 11.1054 3.55306 11.2929 3.36553C11.4804 3.17799 11.7348 3.07263 12 3.07263ZM7 7.07263H17V5.07263H19V19.0726H5V5.07263H7V7.07263ZM7.5 13.5726L9 12.0726L11 14.0726L15.5 9.57263L17 11.0726L11 17.0726L7.5 13.5726Z"
                        fill={colors.ICON}
                      />
                    </svg>
                    <Typography.Text>
                      {progress.totalAssessments} Assignments
                    </Typography.Text>
                  </div>
                  <div className="courseOverview__description__list__item">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 11.9784L10.74 14.7284L8 15.9784L10.74 17.2384L12 19.9784L13.25 17.2384L16 15.9784L13.25 14.7284L12 11.9784ZM6 2.97839H14L20 8.97839V20.9784C20 22.0784 19.1 22.9784 18 22.9784H6C4.9 22.9784 4 22.0784 4 20.9784V4.97839C4 3.87839 4.9 2.97839 6 2.97839ZM6 4.97839V20.9784H18V9.97839H13V4.97839H6Z"
                        fill={colors.ICON}
                      />
                    </svg>
                    <Typography.Text>
                      {progress.totalResources} Resource files
                    </Typography.Text>
                  </div>
                  <div className="courseOverview__description__list__item">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.0833 10.0833H16.5C16.2569 10.0833 16.0237 10.1799 15.8518 10.3518C15.6799 10.5237 15.5833 10.7569 15.5833 11V19.25C15.5833 19.4931 15.6799 19.7263 15.8518 19.8982C16.0237 20.0701 16.2569 20.1667 16.5 20.1667H21.0833C21.3264 20.1667 21.5596 20.0701 21.7315 19.8982C21.9034 19.7263 22 19.4931 22 19.25V11C22 10.7569 21.9034 10.5237 21.7315 10.3518C21.5596 10.1799 21.3264 10.0833 21.0833 10.0833ZM21.0833 18.3333H16.5V11.9167H21.0833V18.3333ZM18.3333 1.83334H1.83333C0.815833 1.83334 0 2.64918 0 3.66668V14.6667C0 15.1529 0.193154 15.6192 0.536971 15.963C0.880788 16.3069 1.3471 16.5 1.83333 16.5H8.25V18.3333H6.41667V20.1667H13.75V18.3333H11.9167V16.5H13.75V14.6667H1.83333V3.66668H18.3333V8.25001H20.1667V3.66668C20.1667 2.64918 19.3417 1.83334 18.3333 1.83334ZM10.9725 8.25001L10.0833 5.50001L9.19417 8.25001H6.41667L8.68083 9.86334L7.81917 12.5308L10.0833 10.8808L12.3475 12.5308L11.4858 9.86334L13.75 8.25001H10.9725Z"
                        fill={colors.ICON}
                      />
                    </svg>
                    <Typography.Text>
                      Access on mobile, desktop and TV
                    </Typography.Text>
                  </div>
                  <div className="courseOverview__description__list__item">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13 21L15 20L17 21V14H13M17 9V7L15 8L13 7V9L11 10L13 11V13L15 12L17 13V11L19 10M20 3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5V15C2 15.5304 2.21071 16.0391 2.58579 16.4142C2.96086 16.7893 3.46957 17 4 17H11V15H4V5H20V15H19V17H20C20.5304 17 21.0391 16.7893 21.4142 16.4142C21.7893 16.0391 22 15.5304 22 15V5C22 4.46957 21.7893 3.96086 21.4142 3.58579C21.0391 3.21071 20.5304 3 20 3ZM11 8H5V6H11M9 11H5V9H9M11 14H5V12H11V14Z"
                        fill={colors.ICON}
                      />
                    </svg>

                    <Typography.Text>Certificate of completion</Typography.Text>
                  </div>
                </div>
              ) : null}
              <Typography.Title level={5} className="courseOverview__cardTitle">
                Description
              </Typography.Title>
              <Typography.Paragraph
                ellipsis={{ rows: 5, expandable: true, symbol: 'See more' }}
                className="descriptionCard__description">
                <ReactLinkify>
                  {HtmlParser(course.description ?? '')}
                </ReactLinkify>
              </Typography.Paragraph>
            </div>
          </div>
        ) : null}
      </BottomSheet>
      {course ? (
        <RatingModal
          show={showRatingModal}
          onClose={() => setShowRatingModal(false)}
          course={course}
          courseRating={courseRating}
          courseReview={courseReview}
          onSaved={(rating, review) => {
            setCourseRating(rating);
            setCourseReview(review || '');
          }}
        />
      ) : null}
    </>
  );
};

export default CourseOverview;
