import React, { memo, useMemo, useState } from 'react';

import { Avatar, Badge, Button, message, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import moment from 'moment';
import {
  IoBan,
  IoCalendarOutline,
  IoCheckmark,
  IoPricetags,
} from 'react-icons/io5';
import Linkify from 'react-linkify';
import { BottomSheet } from 'react-spring-bottom-sheet';

import UserAPI from '../../../api/UserAPI';
import BottomSheetTile from '../../../components/BottomSheetTile/BottomSheetTile';
import MenuButton from '../../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import SocialMediaLink from '../../../components/SocialMediaLink/SocialMediaLink';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppNavigate } from '../../../shared/hooks';
import { nFormat } from '../../../shared/utils';
import { IPointsConfig } from '../../../types/HostMetadata';
import { ROUTES } from '../../../types/routes';
import ChatAvatar from '../../Messages/components/ChatAvatar/ChatAvatar';
import PointsPill from '../../Profile/screens/Leaderboard/components/PointsPill/PointsPill';
import { IProfileDetailsResponse } from '../types';

interface Props {
  profileDetails: IProfileDetailsResponse;
  setProfileDetails: (details: IProfileDetailsResponse) => void;
  pointsConfig: IPointsConfig;
  isTagMango: boolean;
  mangoesTitle: string;
  userId: string;
}

const UserProfileCard: React.FC<Props> = ({
  profileDetails,
  setProfileDetails,
  pointsConfig,
  isTagMango,
  mangoesTitle,
  userId,
}) => {
  const { colors } = useTheme();
  const navigate = useAppNavigate();

  const [isBottomSheetVisible, setIsBottomSheetVisible] =
    useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  // const { pointsImage, pointsName } = pointsConfig;

  const pointsImage = pointsConfig?.pointsImage;
  const pointsName = pointsConfig?.pointsName;

  const state = useMemo(() => {
    const {
      accountType,
      userProfile: {
        about,
        styledAbout,
        score,
        badges,
        facebook,
        linkedin,
        instagram,
        twitter,
        youtube,
        isBlockedFromCommunityEngagement,
      },
    } = profileDetails;

    return {
      isCreatorAccount: accountType === 'creator',
      isSubscriberAccount: accountType === 'subscriber',
      aboutContent: styledAbout ?? about,
      isGamifiedSubscriber:
        accountType !== 'creator' && typeof score === 'number' && !isTagMango,
      isPointsAvailable: pointsConfig && typeof score === 'number',
      isBadgesAvailable: badges && Array.isArray(badges) && badges.length > 0,
      isSocialLinksAvailable:
        facebook || linkedin || instagram || twitter || youtube,
      isBlocked: profileDetails ? isBlockedFromCommunityEngagement : false,
    };
  }, [profileDetails, isTagMango, pointsConfig]);

  const showConfirmationDialog = () => {
    setIsDialogVisible(true);
  };

  const hideConfirmationDialog = () => {
    setIsDialogVisible(false);
  };

  const showBottomSheet = () => {
    setIsBottomSheetVisible(true);
  };

  const hideBottomSheet = () => {
    setIsBottomSheetVisible(false);
  };

  const handleBlockSubscriber = async () => {
    setUpdating(true);
    try {
      const res = await UserAPI.blockUser(userId, !state.isBlocked);
      if (res.status === 200) {
        message.success(
          !state.isBlocked
            ? 'User blocked successfully!'
            : 'User unblocked successfully!',
        );
        setProfileDetails({
          ...profileDetails,
          userProfile: {
            ...profileDetails.userProfile,
            isBlockedFromCommunityEngagement: !state.isBlocked,
          },
        });
        hideConfirmationDialog();
      } else {
        message.error('Something went wrong.');
      }
    } catch (error) {
      message.error('Something went wrong.');
    } finally {
      setUpdating(false);
    }
  };

  const ProfileDetailsComponent = useMemo(() => {
    const { viewerType, isViewerSelf, storefrontLink, userProfile, allowChat } =
      profileDetails;
    const {
      profilePicUrl,
      name,
      facebook,
      linkedin,
      instagram,
      twitter,
      youtube,
      createdAt,
      score,
      leaderboardRank,
      badges,
      isBlockedFromCommunityEngagement,
    } = userProfile;

    return (
      <div className="profile-card">
        <div className="name-wrapper">
          <Badge
            count={
              state.isGamifiedSubscriber
                ? nFormat(leaderboardRank ?? 0, 999, 2)
                : 0
            }
            overflowCount={99}
            title={`Your rank - ${
              state.isGamifiedSubscriber
                ? nFormat(leaderboardRank ?? 0, 999, 2)
                : 0
            }`}
            offset={[-5, 50]}
            color={colors.PRIMARY}>
            <ChatAvatar
              size={65}
              url={profilePicUrl}
              name={name}
              border={1}
              selected
            />
          </Badge>
          <div className="name-wrapper__content">
            <Typography.Title level={4} className="title">
              {name}
            </Typography.Title>
            {state.isCreatorAccount ? (
              <Typography.Text className="creator-tag">CREATOR</Typography.Text>
            ) : null}
          </div>
        </div>

        {state.isGamifiedSubscriber ? (
          <div className="gamification-details">
            {score ? (
              <PointsPill
                points={score ?? 0}
                icon={pointsImage}
                name={pointsName}
                iconSize={20}
              />
            ) : null}

            {state.isBadgesAvailable ? (
              <Avatar.Group maxCount={3}>
                {badges?.map((elem) => (
                  <Avatar src={elem.imageUrl} size={32} />
                ))}
              </Avatar.Group>
            ) : null}
          </div>
        ) : null}

        {state.aboutContent ? (
          <Linkify>
            <Typography.Paragraph
              className="bio-description"
              style={{ whiteSpace: 'pre-wrap' }}
              ellipsis={{
                rows: 6,
                expandable: true,
                symbol: 'See more',
              }}>
              {HtmlParser(state.aboutContent)}
            </Typography.Paragraph>
          </Linkify>
        ) : null}

        {createdAt ? (
          <div className="join-date">
            <IoCalendarOutline size={14} />
            Joined on {moment(createdAt).format('MMM DD, YYYY')}
          </div>
        ) : null}

        {isBlockedFromCommunityEngagement ? (
          <div className="blocked-message">
            <IoBan size={20} />
            Profile has been blocked by the creator from posting or commenting
            on the Feed Page.
          </div>
        ) : null}

        {state.isSocialLinksAvailable ? (
          <div className="socials-wrapper">
            {facebook ? (
              <SocialMediaLink type="facebook" url={facebook} />
            ) : null}
            {linkedin ? (
              <SocialMediaLink type="linkedin" url={linkedin ?? ''} />
            ) : null}
            {instagram ? (
              <SocialMediaLink type="instagram" url={instagram ?? ''} />
            ) : null}
            {twitter ? (
              <SocialMediaLink type="twitter" url={twitter ?? ''} />
            ) : null}
            {youtube ? (
              <SocialMediaLink type="youtube" url={youtube ?? ''} />
            ) : null}
          </div>
        ) : null}

        <div className="action-wrapper">
          {state.isCreatorAccount && storefrontLink ? (
            <>
              <Button
                className="siteBtn grayBtn primaryBtn"
                onClick={() => navigate(ROUTES.STOREFRONT)}>
                View Storefront
              </Button>
            </>
          ) : null}
          {!isViewerSelf && allowChat ? (
            <>
              <Button
                className="siteBtn grayBtn primaryBtn"
                onClick={() =>
                  navigate(ROUTES.MESSAGE_DM_PARTICIPANT, {
                    otherParticipantId: userId,
                  })
                }>
                Chat
              </Button>
            </>
          ) : null}
          {isViewerSelf ? (
            <>
              <Button
                className="siteBtn grayBtn"
                onClick={() => navigate(ROUTES.PERSONAL_INFO)}>
                Edit Profile
              </Button>
            </>
          ) : null}

          {viewerType === 'creator' &&
          state.isSubscriberAccount &&
          !isTagMango ? (
            <>
              <MenuButton
                className="siteBtn grayBtn menuBtn"
                onClick={showBottomSheet}
              />
            </>
          ) : null}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileDetails,
    state.isGamifiedSubscriber,
    state.isCreatorAccount,
    state.isBadgesAvailable,
    state.aboutContent,
    state.isSocialLinksAvailable,
    state.isSubscriberAccount,
    colors.PRIMARY,
    pointsImage,
    pointsName,
    isTagMango,
    navigate,
    userId,
  ]);

  return (
    <>
      {ProfileDetailsComponent}
      <BottomSheet open={isBottomSheetVisible} onDismiss={hideBottomSheet}>
        <BottomSheetTile
          title={`${mangoesTitle.toTitleCase()} Purchased`}
          icon={<IoPricetags size={20} color={colors.TEXT2} />}
          onClick={() => {
            hideBottomSheet();
            navigate(ROUTES.MANGOES_PURCHASED, { userId });
          }}
        />
        {state.isSubscriberAccount &&
        profileDetails.viewerType === 'creator' &&
        !profileDetails.isViewerSelf ? (
          <>
            <hr className="dropdownDivider" />
            <BottomSheetTile
              title={state.isBlocked ? 'Unblock User' : 'Block User'}
              description={
                state.isBlocked
                  ? 'Allow this user to post and comment on your feed'
                  : 'Prevent this user from posting and commenting on your feed'
              }
              icon={
                state.isBlocked ? (
                  <IoCheckmark size={20} color={colors.GREEN} />
                ) : (
                  <IoBan size={20} color={colors.DANGER} />
                )
              }
              onClick={() => {
                hideBottomSheet();
                showConfirmationDialog();
              }}
              color={state.isBlocked ? colors.GREEN : colors.DANGER}
            />
          </>
        ) : null}
      </BottomSheet>
      <ConfirmationModal
        open={isDialogVisible}
        loading={updating}
        title={state.isBlocked ? 'Unblock User' : 'Block User'}
        message={
          state.isBlocked
            ? 'Are you sure you want to unblock this user?'
            : 'Are you sure you want to block this user?'
        }
        okayButtonColor={state.isBlocked ? colors.GREEN : colors.DANGER}
        okayButtonText={state.isBlocked ? 'Unblock User' : 'Block User'}
        cancelButtonText="Cancel"
        handleOk={handleBlockSubscriber}
        handleCancel={hideConfirmationDialog}
      />
    </>
  );
};

export default memo(UserProfileCard);
