/* eslint-disable @typescript-eslint/naming-convention */
import './styles.scss';

import React, { useEffect, useState } from 'react';

import { message, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { MdCameraAlt } from 'react-icons/md';

import ChatAPI from '../../../../api/ChatAPI';
import ImgCrop from '../../../../components/ImgCrop/ImgCrop';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { updateMangoRoomPicture } from '../../../../context/thunks/chatThunks';
import { validImageExtensions } from '../../../../shared/data';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { getBase64 } from '../../../../shared/utils';
import { Participant } from '../../../../types/chatModels/MangoRoomListResponse';
import { IMangoRoomDetails } from '../../../../types/messageTypes';
import ChatAvatar from '../ChatAvatar/ChatAvatar';

interface Props {
  setRoomDetails: (roomDetails: IMangoRoomDetails) => void;
}

const MAX_FILE_SIZE = 1024;

const MangoChatScreenHeader: React.FC<IMangoRoomDetails & Props> = ({
  setRoomDetails,
  ...roomDetails
}) => {
  const {
    _id: roomId,
    title,
    picUrl,
    participants,
    participantCount,
    creator: { _id: creatorId },
  } = roomDetails;
  const { colors } = useTheme();
  const dispatch = useAppDispatch();

  const { id: userId } = useAppSelector((state) => state.user);

  const isCreator = creatorId === userId;

  const [file, setFile] = useState<RcFile>();
  const [loading, setLoading] = useState<boolean>(false);

  const uploadProfileImage = async () => {
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('image', file);
        const res = await ChatAPI.uploadRoomPicture(roomId, formData);
        if (res.status === 200) {
          setRoomDetails({
            ...roomDetails,
            picUrl: res.data.result.picUrl,
          });
          dispatch(updateMangoRoomPicture(roomId, res.data.result.picUrl));
          message.success('Image uploaded successfully');
        } else {
          message.error('Error uploading image.');
        }
      } catch (err) {
        // logThis(Error)
        message.error('Error uploading image.');
      } finally {
        setLoading(false);
        setFile(undefined);
      }
    }
  };

  useEffect(() => {
    if (file) {
      getBase64(file, () => {
        uploadProfileImage();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const beforeUpload = (inputFile: RcFile) => {
    const isValidExtension =
      validImageExtensions.indexOf(inputFile.type) !== -1;

    if (!isValidExtension) {
      message.error('You can only valid image type!');
    }

    const isValidSize = inputFile.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isValidSize) {
      message.error('Image must smaller than 1024MB!');
    }

    if (isValidExtension && isValidSize) {
      setFile(inputFile);
    }
    return false;
  };

  const getAvatar = (url: string, index: number) => (
    <div key={`${index}`} style={{ marginLeft: index > 0 ? -8 : 0, zIndex: 1 }}>
      <ChatAvatar
        size={22}
        url={url}
        style={{ backgroundColor: colors.BACKGROUND }}
        border={1}
      />
    </div>
  );
  const getParticipantsGroup = (
    displayParticipantsArray: Participant[],
    totalParticipants: number,
  ) => {
    if (displayParticipantsArray?.length >= 3) {
      return (
        <>
          {getAvatar(
            displayParticipantsArray[displayParticipantsArray.length - 1]
              ?.profilePicUrl,
            0,
          )}
          {getAvatar(
            displayParticipantsArray[displayParticipantsArray.length - 2]
              ?.profilePicUrl,
            1,
          )}
          {getAvatar(
            displayParticipantsArray[displayParticipantsArray.length - 3]
              ?.profilePicUrl,
            2,
          )}
          {totalParticipants - displayParticipantsArray.length > 0 ? (
            <div className="participant-extra-text__wrapper">
              <Typography.Text className="participant-extra-text">
                +{totalParticipants - displayParticipantsArray.length}
              </Typography.Text>
            </div>
          ) : null}
        </>
      );
    }
    return displayParticipantsArray.map((participant, index) =>
      getAvatar(participant?.profilePicUrl, index),
    );
  };

  return (
    <div className="mangoChatScreenHeader">
      {/* @ts-ignore */}
      <ImgCrop rotate grid aspect={1}>
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          accept="*"
          disabled={!isCreator}>
          <div className="avatarHolder">
            {loading ? (
              <Loader
                size="large"
                style={{
                  width: 64,
                }}
              />
            ) : (
              <ChatAvatar
                size={76}
                url={picUrl}
                name={title}
                selected={isCreator}
                selectedColor={colors.SECONDARY}
              />
            )}
            {isCreator && (
              <div className="camera__container">
                <MdCameraAlt color={colors.ICON} size={20} />
              </div>
            )}
          </div>
        </Upload>
      </ImgCrop>
      <Typography.Title
        level={3}
        ellipsis={{
          rows: 1,
          expandable: false,
        }}
        className="mangoChatScreenHeader__title">
        {title}
      </Typography.Title>
      {participantCount > 0 && (
        <div className="mangoChatScreenHeader__participants">
          {getParticipantsGroup(
            participants,
            // .filter((p) => p._id !== userDetails.id),
            participantCount,
          )}
          <Typography.Text className="mangoChatScreenHeader__participants__extra--text">
            joined this room
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default React.memo(MangoChatScreenHeader);
