import './styles.scss';

import React, { useEffect, useRef, useState } from 'react';

import { message, Modal, Typography } from 'antd';
import { BiCommentCheck, BiCommentX } from 'react-icons/bi';
import { useParams, useSearchParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import FeedAPI from '../../api/FeedAPI';
import CopyLinkSvg from '../../assets/svg/CopyLink';
import CopyText from '../../assets/svg/CopyText';
import DeleteSvg from '../../assets/svg/Delete';
import EditPostSvg from '../../assets/svg/Edit';
import BottomSheetTile from '../../components/BottomSheetTile/BottomSheetTile';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import MenuButton from '../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import { removePost, updatePost } from '../../context/activityReducer';
import { useTheme } from '../../context/ThemeProvider';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  copyToClipboard,
  handleShareLink,
  navigateToOnboardingScreen,
} from '../../shared/utils';
import { CommentObject, ICommentSelected } from '../../types/commentTypes';
import { ROUTES } from '../../types/routes';
import EditPost from '../CreatePost/EditPost';
import PostComment from '../Feed/Posts/PostComment/PostComment';
import PostCommentInput, {
  PostCommentInputRefProps,
} from '../Feed/Posts/PostComment/PostCommentInput';
import ViewMoreComment from '../Feed/Posts/PostComment/ViewMoreComment';
import PostBlocked from '../Feed/Posts/PostItem/PostBlocked';
import PostContent from '../Feed/Posts/PostItem/PostContent';
import PostHeader from '../Feed/Posts/PostItem/PostHeader';
import PostMedia from '../Feed/Posts/PostItem/PostMedia';
import PostPoll from '../Feed/Posts/PostItem/PostPoll';
import PostStats from '../Feed/Posts/PostItem/PostStats';
import PostTags from '../Feed/Posts/PostItem/PostTags';
import PostSkeleton from '../Feed/Posts/PostSkeleton';

interface Props {
  postId?: string;
  handleBack?: () => void;
}

type CommentListItem =
  | { type: 'comment'; data: CommentObject }
  | { type: 'paginator'; direction: 'old' | 'new'; lastCommentRef: string };

const PostDetailsScreen: React.FC<Props> = ({
  postId: modalPostId,
  handleBack: modalHandleBack,
}) => {
  const { colors } = useTheme();
  const {
    isLoggedIn,
    hostMetadata: { offeringTitle },
    deeplinkUrl,
    isTagMango,
  } = useAppSelector((state) => state.app);
  const { postState } = useAppSelector((state) => state.activity);
  const userDetails = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { postId: paramPostId } = useParams<{ postId: string }>();
  const postId = modalPostId || paramPostId;
  const [searchParams] = useSearchParams();

  const [curPost, setCurPost] = useState<any>(null);
  const [loadingPost, setLoadingPost] = useState(true);
  const [postStatus, setPostStatus] = useState<'regular' | 'denied'>('regular');
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);

  const isMyPost = (curPost?.creatorId || curPost?.creator) === userDetails.id;

  const [comments, setComments] = useState<CommentListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<ICommentSelected>({
    type: null,
    commentId: null,
    replyId: null,
    userId: null,
    userName: null,
  });
  const itemsRef = useRef<any[]>([]);
  const commentInputRef = useRef<PostCommentInputRefProps>(null);
  const [sendingComment, setSendingComment] = useState(false);

  const getTitle = () => {
    if (loadingPost && !curPost?._id) return 'Loading User';
    const name: string =
      curPost?.creatorName?.split(' ')?.[0] || 'Unknown User';
    return name.length > 9 ? `${name.substring(0, 9)}...` : name;
  };

  const copyLinktoClipboard = async () => {
    if (curPost?.shortUrl) {
      handleShareLink(curPost?.shortUrl);
    } else {
      handleShareLink(`post/${curPost?._id}`);
    }
  };

  const handleBack = () => {
    if (modalHandleBack) {
      modalHandleBack();
      return;
    }
    if (navigate.canGoBack()) navigate.goBack();
    else {
      navigateToOnboardingScreen(userDetails.type, navigate);
    }
  };

  const onDelete = () => {
    const errorMsg = 'Failed to delete post';
    if (postId) {
      setIsDialogVisible(false);
      handleBack();
      dispatch(removePost(postId));
      FeedAPI.deletePost(postId);
      message.success('Post deleted successfully');
    } else {
      message.error(errorMsg);
    }
  };

  const getPost = async (id: string) => {
    const errorMsg = 'Failed to load post';
    try {
      const res = await FeedAPI.getPostById(id);
      if (res.status === 200) {
        setCurPost(res.data.result.posts[0]);
        setPostStatus('regular');
        setLoadingPost(false);
      } else {
        message.error(errorMsg);
      }
    } catch (err: any) {
      if (err?.response && err?.response.status === 403) {
        setCurPost(err.response.data.result);
        setPostStatus('denied');
        setLoadingPost(false);
      } else if (err?.response && err?.response.status === 400) {
        message.error('Post not found');
        handleBack();
      } else {
        message.error(errorMsg);
        handleBack();
      }
    }
  };

  const loadCommentsFromMiddle = async (comment: string) => {
    try {
      const result = await FeedAPI.getCommentsForDeeplink(comment);
      const { allComments } = result.data.result;
      const newComments: CommentListItem[] = [
        {
          type: 'paginator',
          direction: 'new',
          lastCommentRef: allComments[0]._id,
        },
        ...allComments.map(
          (item) => ({ type: 'comment', data: item }) as CommentListItem,
        ),
        {
          type: 'paginator',
          direction: 'old',
          lastCommentRef: allComments[allComments.length - 1]._id,
        },
      ];
      setComments(newComments);
    } catch (err) {
      console.log(err);
    }
  };

  const getCommentsBeforeOrAfter = async (
    lastCommentId: string,
    direction: 'old' | 'new',
  ) => {
    const errorMsg = 'Failed to load comments';
    try {
      if (isLoggedIn && postId) {
        const res = await FeedAPI.getCommentsByPost(
          postId,
          direction,
          lastCommentId || undefined,
        );
        if (res.status === 200) {
          let newComments: CommentListItem[] = lastCommentId
            ? [
                ...comments.filter(
                  (it) =>
                    it.type !== 'paginator' ||
                    (it.type === 'paginator' &&
                      it.lastCommentRef !== lastCommentId),
                ),
              ]
            : [];
          // res.data.result.result.forEach((comment) => {
          //   newComments.push({ type: 'comment', data: comment });
          // });
          const commentsFromResponse: CommentListItem[] =
            res.data.result.result.map((comment) => ({
              type: 'comment',
              data: comment,
            }));
          if (direction === 'old') {
            newComments = [...newComments, ...commentsFromResponse];
          } else {
            newComments = [...commentsFromResponse, ...newComments];
          }
          if (res.data.result.remainingCount > 0) {
            const temp: { type: 'comment'; data: CommentObject }[] =
              newComments.filter((cmnt) => cmnt.type === 'comment') as any;
            const lastCommentRef =
              temp.length > 0 ? temp[temp.length - 1].data._id : '';
            if (direction === 'old') {
              newComments = [
                ...newComments,
                {
                  type: 'paginator',
                  direction,
                  lastCommentRef,
                },
              ];
            } else {
              newComments = [
                {
                  type: 'paginator',
                  direction,
                  // @ts-ignore
                  lastCommentRef: newComments[0].data._id,
                },
                ...newComments,
              ];
            }
          }
          setComments(newComments);
        }
      }
    } catch (err: any) {
      if (
        err?.response &&
        (err?.response.status === 403 || err?.response.status === 400)
      ) {
        return;
      }
      message.error(errorMsg);
    }
  };

  useEffect(() => {
    const commentRef = searchParams.get('commentId');
    // load the comment section
    if (commentRef) {
      // this page was probably opened with a deeplink with comment reference, load comments section from middle
      loadCommentsFromMiddle(commentRef);
    } else {
      // load comment section from start
      // getComments(1);
      getCommentsBeforeOrAfter('', 'old');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, comments.length);
  }, [comments]);

  useEffect(() => {
    if (postId) {
      const val = postState.posts.find((p) => p._id === postId);
      if (val) {
        setCurPost(val);
        setLoadingPost(false);
      } else {
        getPost(postId);
      }
    } else {
      navigateToOnboardingScreen(userDetails.type, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, postState.posts]);

  useEffect(() => {
    if (selected.commentId) {
      commentInputRef.current?.focusInput();
    }
  }, [selected]);

  const onEnableOrDisableComments = async (value: boolean = false) => {
    const errorMsg = `Failed to ${value ? 'disable' : 'enable'} comments`;
    if (curPost && curPost._id) {
      const newPost = {
        ...curPost,
        disableEngagement: value,
      };
      dispatch(updatePost(newPost));
      setCurPost(newPost);
      setIsBottomSheetVisible(false);
      try {
        const resp = await FeedAPI.toggleEnableOrDisableComments(
          curPost._id,
          value,
        );
        if (resp.status === 200) {
          message.success(
            `Comments ${value ? 'disabled' : 'enabled'} successfully!`,
          );
        } else {
          message.error(errorMsg);
        }
      } catch {
        message.error(errorMsg);
      }
    }
  };

  return (
    <>
      <div className="postdetails__container">
        <Header
          title={`${getTitle()}'s post`}
          handleBack={handleBack}
          actionItems={
            postStatus === 'denied'
              ? []
              : (curPost && [
                  <MenuButton
                    onClick={() => {
                      setIsBottomSheetVisible(true);
                    }}
                  />,
                ]) ||
                []
          }
        />
        <div className="scroll__container">
          <div
            style={{
              borderBottom: `1px solid ${colors.BORDER}`,
            }}>
            {!loadingPost && curPost ? (
              <>
                <PostTags tags={curPost.tags || []} />
                <PostHeader
                  post={curPost}
                  mode="details"
                  postStatus={postStatus}
                />
                {postStatus === 'denied' ? (
                  <div className="creatorPostMediaWrapper">
                    <PostBlocked
                      userLoggedIn={isLoggedIn}
                      onClick={() => {
                        if (!isLoggedIn) {
                          navigate(ROUTES.LOGIN, undefined, {
                            state: {
                              redirectTo: ROUTES.POST_DETAILS.replace(
                                ':postId',
                                postId || '',
                              ),
                            },
                          });
                          return;
                        }
                        let url;
                        if (curPost.mangoArr?.length === 1) {
                          url = isTagMango
                            ? `${deeplinkUrl}mangocheckout/${curPost.mangoArr[0]._id}`
                            : `${deeplinkUrl}web/checkout/${curPost.mangoArr[0]._id}`;
                          url += '?purchaseNow=true';
                        } else {
                          url = `${deeplinkUrl}creator/profile/${
                            curPost.creatorId || curPost.creator
                          }`;
                        }
                        window.open(url);
                      }}
                    />
                  </div>
                ) : null}
                {curPost.caption && postStatus === 'regular' ? (
                  <PostContent
                    caption={curPost.caption}
                    maxLines={curPost.contentUrl ? 2 : 5}
                    onCaptionPressed={() => {}}
                  />
                ) : null}
                {postStatus === 'regular' ? (
                  <PostMedia
                    isDrmEnabled={curPost.isDrmEnabled}
                    vdoCipherId={curPost.videoCipherId}
                    url={curPost.contentUrl}
                    type={curPost.contentType}
                    // eslint-disable-next-line no-underscore-dangle
                    id={curPost._id}
                    isCompressDone={curPost?.isCompressDone}
                    index={0}
                    thumbnail={curPost.thumbnail}
                    compressedUrl={curPost.compressedVideoUrl}
                    hlsUrl={curPost.compressedVideoUrlHls}
                    dashUrl={curPost.compressedVideoUrlDash}
                    contentId={curPost.qencodePostId}
                    // drmType={checkBrowser().drmType}
                    typeOfPost="post"
                    hasError={curPost.isCompressError}
                    errorDescription={curPost.compressErrorDescription}
                  />
                ) : null}
                {curPost.poll && curPost.contentType === 'poll' ? (
                  <PostPoll
                    pollId={curPost.poll._id}
                    isOwnPost={curPost.creatorId === userDetails.id}
                    isAnswered={curPost.poll.hasVoted}
                    answerOption={curPost.poll.voteDetails?.pollOption}
                    question={curPost.poll.question}
                    options={curPost.poll.pollOptions}
                    individualVotes={curPost.poll.voteCounts}
                    totalVotes={curPost.poll.totalVotes}
                    endDate={curPost.poll.pollEndAt}
                    updatePost={(newPoll: any) => {
                      dispatch(
                        updatePost({
                          ...curPost,
                          poll: newPoll,
                        }),
                      );
                      setCurPost({ ...curPost, poll: newPoll });
                    }}
                  />
                ) : null}
                {postStatus === 'regular' ? (
                  <PostStats
                    post={curPost}
                    onCommentsPressed={() => {
                      setSelected({
                        type: null,
                        commentId: null,
                        replyId: null,
                        userId: null,
                        userName: null,
                      });
                    }}
                  />
                ) : null}
              </>
            ) : (
              <PostSkeleton />
            )}
          </div>
          {postStatus === 'regular' &&
          !loadingPost &&
          !curPost?.disableEngagement ? (
            <div className="postCommentListWrapper">
              <ul className="postCommentList">
                {comments.map((commentItem, index) => {
                  if (commentItem.type === 'comment') {
                    const { data: item } = commentItem;
                    return (
                      <PostComment
                        creator={curPost.creatorId}
                        onRemoveComment={async (commentId: string) => {
                          const newComments = comments.filter(
                            (cmnt) =>
                              cmnt.type === 'comment' &&
                              cmnt.data?._id !== commentId,
                          );
                          const replyCount =
                            (
                              comments.filter(
                                (cmnt) =>
                                  cmnt.type === 'comment' &&
                                  cmnt.data?._id === commentId,
                                // @ts-ignore
                              )[0].data as CommentObject
                            ).replyCount || 0;
                          setComments(newComments);
                          dispatch(
                            updatePost({
                              ...curPost,
                              commentCount: curPost.commentCount - 1,
                              replyCount: curPost.replyCount - replyCount,
                            }),
                          );
                          try {
                            await FeedAPI.deleteComment(commentId);
                          } catch (err) {
                            message.error('Failed to remove comment!');
                          }
                        }}
                        ref={(i) => {
                          itemsRef.current[index] = i;
                        }}
                        key={item._id}
                        userId={item.userId || ''}
                        name={item.userName || ''}
                        profilePic={item.imgUrl || ''}
                        content={item.text}
                        liked={item.liked}
                        likeCount={item?.likedBy?.length || 0}
                        replyCount={item.replyCount}
                        onUpdateReplyCount={(newCount) => {
                          const newComments = [...comments];
                          // @ts-ignore
                          newComments[index].data.replyCount = newCount;
                          setComments(newComments);
                        }}
                        createdAt={item.createdAt}
                        commentId={item._id}
                        selected={selected}
                        setSelected={(val: ICommentSelected) =>
                          setSelected(val)
                        }
                        toggleLikeComment={() =>
                          setComments((prev) => {
                            const newComments = prev.map<CommentListItem>(
                              (commentElement) => {
                                if (commentElement.type !== 'comment')
                                  return commentElement;
                                const comment: CommentObject =
                                  commentElement.data;
                                if (comment._id === item._id) {
                                  const newComment = { ...comment };
                                  newComment.liked = !comment.liked;
                                  if (comment.liked) {
                                    newComment.likedBy = comment.likedBy.filter(
                                      (x: any) => x !== userDetails.id,
                                    );
                                  } else {
                                    newComment.likedBy.push(userDetails.id);
                                  }
                                  return { type: 'comment', data: newComment };
                                }
                                // eslint-disable-next-line consistent-return
                                return { type: 'comment', data: comment };
                              },
                            );
                            return newComments;
                          })
                        }
                      />
                    );
                  }
                  // load paginator here
                  if (loading) {
                    return <Loader />;
                  }
                  return (
                    <ViewMoreComment
                      onClick={async () => {
                        setLoading(true);
                        try {
                          await getCommentsBeforeOrAfter(
                            commentItem.lastCommentRef,
                            commentItem.direction,
                          );
                          setLoading(false);
                        } catch (err) {
                          message.error('Failed to fetch comments!');
                        } finally {
                          setLoading(false);
                        }
                      }}
                    />
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
        {!loadingPost &&
        postStatus === 'regular' &&
        !curPost?.disableEngagement &&
        !userDetails.isBlockedFromCommunityEngagement ? (
          <PostCommentInput
            ref={commentInputRef}
            reply={selected}
            onReplyClose={() => {
              setSelected({
                type: null,
                commentId: null,
                replyId: null,
                userId: null,
                userName: null,
              });
            }}
            sending={sendingComment}
            postId={curPost?._id || ''}
            onComment={async (newComment) => {
              if (newComment.trim() === '') return;
              try {
                setSendingComment(true);
                const res = await FeedAPI.createComment({
                  postId: curPost._id ?? '',
                  text: newComment.trim(),
                  commentRef: selected.commentId ?? undefined,
                });
                // console.log('comment registered!', res.data);
                if (res.data.type === 'OK') {
                  commentInputRef.current?.clearInput();
                  if (selected.type) {
                    const index = comments.findIndex(
                      (comment) =>
                        comment.type === 'comment' &&
                        comment.data._id === selected.commentId,
                    );
                    itemsRef.current[index].addReply({
                      ...res.data.result,
                      checkedlikes: [],
                      imgUrl: userDetails.profilePic,
                      liked: false,
                      replies: [],
                      replyCount: 0,
                      userId: userDetails.id,
                      userName: userDetails.name,
                    });
                    setComments((prev) =>
                      prev.map((e, i) => {
                        if (e.type !== 'comment' || i !== index) return e;

                        return {
                          ...e,
                          data: {
                            ...e.data,
                            replyCount: e.data.replyCount + 1,
                          },
                        };
                      }),
                    );
                    dispatch(
                      updatePost({
                        ...curPost,
                        replyCount: curPost.replyCount + 1,
                      }),
                    );
                  } else {
                    const newComments: CommentListItem[] = [
                      {
                        type: 'comment',
                        // @ts-ignore
                        data: {
                          ...res.data.result,
                          checkedlikes: [],
                          imgUrl: userDetails.profilePic || '',
                          liked: false,
                          replies: [],
                          replyCount: 0,
                          userId: userDetails.id || '',
                          userName: userDetails.name || '',
                        },
                      },
                      ...comments,
                    ];
                    setComments(newComments);
                    dispatch(
                      updatePost({
                        ...curPost,
                        commentCount: curPost.commentCount + 1,
                      }),
                    );
                  }
                }
              } catch (err) {
                message.error('Failed. Try Again!!');
                // console.log('something went wrong', err);
              } finally {
                setSendingComment(false);
                setSelected({
                  type: null,
                  commentId: null,
                  replyId: null,
                  userName: null,
                  userId: null,
                });
              }
            }}
          />
        ) : curPost?.disableEngagement ? (
          <Typography.Paragraph
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '20px 0',
            }}>
            Comments are disabled for this post!
          </Typography.Paragraph>
        ) : userDetails.isBlockedFromCommunityEngagement ? (
          <Typography.Paragraph
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '20px 0',
            }}>
            You are blocked from community engagement by the creator
          </Typography.Paragraph>
        ) : null}
      </div>
      <BottomSheet
        open={isBottomSheetVisible}
        onDismiss={() => setIsBottomSheetVisible(false)}>
        {curPost?.caption && curPost?.contentType !== 'poll' ? (
          <BottomSheetTile
            icon={<CopyText width={20} height={20} />}
            title="Copy Caption"
            onClick={() => {
              copyToClipboard(curPost.caption);
              setIsBottomSheetVisible(false);
            }}
          />
        ) : null}
        <BottomSheetTile
          icon={<CopyLinkSvg width={20} height={20} />}
          title="Copy Link"
          onClick={() => {
            copyLinktoClipboard();
            setIsBottomSheetVisible(false);
          }}
        />
        {postStatus === 'regular' && isMyPost ? (
          <>
            {curPost?.contentType !== 'poll' ? (
              <BottomSheetTile
                icon={<EditPostSvg width={18} height={18} />}
                title="Edit Post"
                onClick={() => {
                  setIsBottomSheetVisible(false);
                  setShowEditPostModal(true);
                }}
              />
            ) : null}
            {!isTagMango ? (
              curPost.disableEngagement ? (
                <BottomSheetTile
                  icon={<BiCommentCheck color={colors.ICON} size={20} />}
                  title="Enable comments"
                  description="Commenting will be enabled in this post"
                  onClick={() => {
                    onEnableOrDisableComments(false);
                  }}
                />
              ) : (
                <BottomSheetTile
                  icon={<BiCommentX color={colors.ICON} size={20} />}
                  title="Disable comments"
                  description="Commenting would be disabled in this post"
                  onClick={() => {
                    onEnableOrDisableComments(true);
                  }}
                />
              )
            ) : null}
            <hr className="dropdownDivider" />
            <BottomSheetTile
              icon={<DeleteSvg width={20} height={20} />}
              warning
              alignIcon="flex-start"
              title="Remove Post"
              description={`The post will be removed from your ${offeringTitle} feed`}
              onClick={() => {
                setIsBottomSheetVisible(false);
                setIsDialogVisible(true);
              }}
            />
          </>
        ) : null}
      </BottomSheet>
      <ConfirmationModal
        title="Remove"
        open={isDialogVisible}
        handleOk={onDelete}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
        message="Do you really want to delete the post?"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
      />
      <Modal
        open={showEditPostModal}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        <EditPost
          post={curPost}
          handleBack={() => {
            setShowEditPostModal(false);
          }}
          onEditCallback={(newPost) => {
            setCurPost(newPost);
          }}
        />
      </Modal>
    </>
  );
};

export default PostDetailsScreen;
