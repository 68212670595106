/* eslint-disable @typescript-eslint/no-use-before-define */
import './styles.scss';

import React from 'react';

import { message, Modal } from 'antd';

import FeedAPI from '../../api/FeedAPI';
import { IMessage } from '../../types/chatModels/ChatMessagesResponse';
import { IReportRequest } from '../../types/feedTypes';
import HeaderTextAction from '../Header/actions/HeaderTextAction';
import Header from '../Header/Header';
import Select from '../Input/Select';
import TextArea from '../Input/TextArea';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  type: 'message' | 'comment';
  comment?: {
    creator: string;
    profilePic: string;
    name: string;
    replyCount?: number;
    content: string;
    liked: boolean;
    likeCount: number;
    createdAt: string;
    userId: string;
    commentId: string;
  };
  message?: IMessage;
  post?: {
    _id: string;
  };
}

const reportTypes = [
  'Spam',
  'Nudity',
  'Violence',
  'Unauthorized Sales',
  'Hate Speech',
  'Harassment',
  'Suicide or Self-Injury',
  'False information',
  'Terrorism',
  'Others',
];

const Report: React.FC<Props> = ({
  showModal,
  closeModal,
  type,
  comment,
  message: messageItem,
  post,
}) => {
  const [reportType, setReportType] = React.useState(reportTypes[0]);
  const [reportText, setReportText] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);

  const onReportClicked = () => {
    if (!reportText || reportText.trim().length === 0) {
      message.error('Please enter a reason');
      return;
    }
    if (!reportType) {
      message.error('Please select a report type');
      return;
    }
    setIsDialogVisible(true);
  };

  const onReport = async () => {
    let data: IReportRequest = {
      reason: reportText,
      category: reportType,
      targetType: type,
      target:
        (type === 'comment'
          ? comment?.commentId
          : type === 'message'
          ? messageItem?._id
          : post?._id) || '',
    };
    try {
      setIsSubmitting(true);
      const res = await FeedAPI.reportMessage(data);
      if (res.status === 200) {
        closeModal();
        setReportText('');
        setReportType(reportTypes[0]);
        message.success(`${type.toTitleCase()} reported successfully`);
      } else {
        message.error('Something went wrong');
      }
    } catch (error: any) {
      console.log(error?.response);
      message.error(`Error while reporting ${type}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        title={
          <Header
            title={`Report ${type.toTitleCase()}`}
            backType="cross"
            handleBack={closeModal}
            actionItems={[
              <HeaderTextAction
                text="Submit"
                loadingText="Submitting"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={onReportClicked}
              />,
            ]}
          />
        }
        destroyOnClose
        className="reportModal"
        closable={false}
        footer={null}
        closeIcon={null}>
        <div className="reportModal__content">
          <Select<string>
            label="Type"
            onChange={(value) => {
              setReportType(value);
            }}
            selected={reportType}
            options={reportTypes}
          />
          <TextArea
            label="Reason"
            onChangeText={(text) => {
              setReportText(text);
            }}
            text={reportText}
          />
        </div>
      </Modal>
      <ConfirmationModal
        open={isDialogVisible}
        message={`Do you really want to report the ${type}?`}
        title="Report"
        okayButtonText="Report"
        cancelButtonText="Cancel"
        handleOk={() => {
          onReport();
          setIsDialogVisible(false);
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
    </>
  );
};
export default React.memo(Report);
