import './styles.scss';

import React, {
  useEffect,
  useMemo,
} from 'react';

import {
  message,
  Typography,
} from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import ChatAPI from '../../../../api/ChatAPI';
import Header from '../../../../components/Header/Header';
import SearchBar from '../../../../components/Header/SearchBar';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { useAppNavigate } from '../../../../shared/hooks';
import {
  Participant,
} from '../../../../types/chatModels/MangoRoomListResponse';
import { ROUTES } from '../../../../types/routes';
import NoDataFound from '../../../Feed/components/NoDataFound/NoDataFound';
import ChatAvatar from '../../components/ChatAvatar/ChatAvatar';

const title = 'New Message';

interface IPeople {
  creators: Participant[];
  peers: Participant[];
}

interface IState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  people: IPeople;
  refreshing: boolean;
}

const SEARCH_THRESHOLD = 3;
const API_LIMIT = 10;

const NewMessage: React.FC = () => {
  const { colors } = useTheme();
  const navigate = useAppNavigate();

  const [state, setState] = React.useState<IState>({
    loading: false,
    page: 1,
    hasMore: true,
    people: {
      creators: [],
      peers: [],
    },
    refreshing: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setSearchValue] = React.useState('');

  const renderItem = (
    item:
      | {
          type: 'header';
          name: 'Creators' | 'Peers';
        }
      | {
          type: 'item';
          item: Participant;
          isCreator: boolean;
        },
  ) => {
    if (item.type === 'header') {
      return (
        <div className="new-message__header">
          <Typography.Text className="new-message__header-text">
            {item.name}
          </Typography.Text>
        </div>
      );
    }
    return (
      <div
        className="new-message__item"
        onClick={() => {
          if (item.item.disabledChat) {
            message.error('This user has disabled messaging!');
          } else {
            navigate(ROUTES.MESSAGE_DM_PARTICIPANT, {
              otherParticipantId: item.item._id,
            });
          }
          //   navigation.navigate('DMCHATSCREEN', {
          //     isOtherParticipantCreator: item.isCreator,
          //     otherParticipantId: item.item._id,
          //     roomProfilePic: item.item.profilePicUrl,
          //     roomName: item.item.name,
          //   });
          // TODO: navigate to DMCHATSCREEN
        }}>
        <ChatAvatar
          size={32}
          url={item?.item.profilePicUrl}
          style={{ backgroundColor: colors.BACKGROUND }}
          border={1}
        />
        <div className="new-message__item-text">
          <Typography.Title
            level={5}
            ellipsis={{ rows: 1, expandable: false }}
            className="new-message__item-text-name">
            {item?.item.name}
          </Typography.Title>
          {item.isCreator && <div className="creator-tag">CREATOR</div>}
        </div>
      </div>
    );
  };

  const fetchInitData = (
    pageCount: number,
    type: 'init' | 'search' = searchValue.length >= SEARCH_THRESHOLD
      ? 'search'
      : 'init',
    searchText: string = searchValue,
    people: IPeople = state.people,
    hasMore: boolean = state.hasMore,
    loading: boolean = state.loading,
    currState: IState = state,
  ) => {
    if (hasMore && !loading) {
      const API =
        type === 'init'
          ? ChatAPI.getNewMessageList
          : ChatAPI.searchNewMessageList;
      setState({ ...state, loading: true });
      API(pageCount, API_LIMIT, searchText)
        .then((res) => {
          let newState = { ...currState, loading: false, refreshing: false };
          if (res.status === 200) {
            const updatedPeople =
              pageCount > 1
                ? {
                    creators: [...people.creators, ...res.data.result[0]],
                    peers: [...people.peers, ...res.data.result[1]],
                  }
                : {
                    creators: res.data.result[0],
                    peers: res.data.result[1],
                  };
            newState = {
              ...newState,
              people: updatedPeople,
              page: pageCount + 1,
              hasMore:
                type === 'search'
                  ? false
                  : res.data.result[0].length + res.data.result[1].length !== 0,
            };
          } else {
            newState = {
              ...newState,
              people: {
                creators: people.creators || [],
                peers: people.peers || [],
              },
              hasMore: false,
            };
            message.error(res.data.message);
          }
          setState({ ...state, ...newState });
        })
        .catch((err) => {
          console.log(err);
          message.error('Could not load contacts');
          setState({ ...state, loading: false });
        });
    }
  };

  useEffect(() => {
    const newState: IState = {
      people: {
        creators: [],
        peers: [],
      },
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setState({ ...state, ...newState });
    if (searchValue.length >= SEARCH_THRESHOLD) {
      fetchInitData(1, 'search', searchValue, state.people, true);
    } else fetchInitData(1, 'init', '', state.people, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const getData = useMemo(() => {
    const res: (
      | { type: 'header'; name: 'Creators' | 'Peers' }
      | { type: 'item'; item: Participant; isCreator: boolean }
    )[] = [];
    if (state.people.creators.length > 0) {
      res.push({ type: 'header', name: 'Creators' });
      const creators = state.people.creators.map((creator) => ({
        type: 'item' as 'item',
        item: creator,
        isCreator: true,
      }));
      res.push(...creators);
    }
    if (state.people.peers.length > 0) {
      res.push({ type: 'header', name: 'Peers' });
      const peers = state.people.peers.map((peer) => ({
        type: 'item' as 'item',
        item: peer,
        isCreator: false,
      }));
      res.push(...peers);
    }
    return res;
  }, [state.people]);

  return (
    <div className="pageRoot new-message">
      <Header title={title} />
      <SearchBar
        onDebounce={(value) => {
          setSearchValue(value);
        }}
        placeholder="Search"
        style={{
          borderBottomWidth: 1,
          borderColor: colors.BORDER,
        }}
      />
      <div className="pageContent pageContentList">
        <EasyVirtualizedScroller
          hasMore={state.hasMore}
          useParentScrollElement
          onLoadMore={async () => fetchInitData(state.page)}>
          {getData.map((item) => renderItem(item))}
        </EasyVirtualizedScroller>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '45px 0',
          }}>
          {state.loading ? (
            <Loader />
          ) : getData.length === 0 ? (
            <NoDataFound title="No contacts found to DM" />
          ) : (
            <Typography.Paragraph
              style={{
                width: '100%',
                textAlign: 'center',
                margin: '20px 0',
              }}>
              That’s it! No more contacts to show.
            </Typography.Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewMessage;
