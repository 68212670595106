import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { message } from 'antd';

import FeedAPI from '../../../../api/FeedAPI';
import Loader from '../../../../components/Loader';
import { useAppSelector } from '../../../../shared/hooks';
import { ICommentSelected } from '../../../../types/commentTypes';
import PostCommentCard from './PostCommentCard';
import ViewMoreComment from './ViewMoreComment';

interface Props {
  creator: string;
  userId: string;
  name: string;
  profilePic: string;
  content: string;
  liked: boolean;
  likeCount: number;
  replyCount: number;
  createdAt: string;
  commentId: string;
  toggleLikeComment: () => void;
  selected: ICommentSelected;
  setSelected: (val: ICommentSelected) => void;
  onRemoveComment: (commentId: string) => void;
  onUpdateReplyCount: (newCount: number) => void;
}

interface RefProps {}

const PostComment: React.ForwardRefRenderFunction<RefProps, Props> = (
  {
    creator,
    userId,
    name,
    profilePic,
    content,
    liked,
    likeCount,
    replyCount,
    createdAt,
    commentId,
    toggleLikeComment,
    selected,
    setSelected,
    onRemoveComment,
    onUpdateReplyCount,
  },
  ref,
) => {
  const [remainingPreviousRepliesCount, setRemainingPreviousRepliesCount] =
    useState(0);
  const [remainingNewerRepliesCount, setRemainingNewerRepliesCount] =
    useState(0);
  const [replies, setReplies] = useState<any[]>([]);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const userDetails = useAppSelector((state) => state.user);
  const [isRepliesVisible, setIsRepliesVisible] = useState<boolean>(false);

  const getRepliesAfter = async (lastReply?: string) => {
    setLoadingNext(true);
    const resp = (
      await FeedAPI.getRepliesForComment(commentId, 'new', lastReply)
    ).data;
    if (resp.type === 'OK') {
      const data = resp.result.result;
      setRemainingNewerRepliesCount(resp.result.remainingCount);
      setReplies((prev) => [...prev, ...data]);
      setLoadingNext(false);
    } else {
      message.error('Failed to load replies');
      setLoadingNext(false);
    }
  };

  const getRepliesBefore = async (lastReply?: string) => {
    setLoadingPrevious(true);
    try {
      const resp = (
        await FeedAPI.getRepliesForComment(commentId, 'old', lastReply)
      ).data;
      if (resp.type === 'OK') {
        const data = resp.result.result;
        setRemainingPreviousRepliesCount(resp.result.remainingCount);
        setReplies((prev) => [...data, ...prev]);
        setLoadingPrevious(false);
      } else {
        message.error('Failed to load replies');
      }
    } catch (err) {
      setLoadingPrevious(false);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      addReply: (reply: any) => {
        setIsRepliesVisible(true);
        if (replyCount > replies.length)
          setRemainingPreviousRepliesCount(replyCount - replies.length);
        setReplies((prev) => [...prev, reply]);
      },
    }),
    [replies.length, replyCount],
  );

  const toggleRepliesLike = (reply: any) => {
    setReplies((prev) =>
      prev.map((r) => {
        if (r._id === reply._id) {
          const newReply = { ...r };
          newReply.liked = !newReply.liked;
          if (r.liked) {
            newReply.likedBy = r.likedBy.filter(
              (x: any) => x !== userDetails.id,
            );
          } else {
            newReply.likedBy.push(userDetails.id);
          }
          return newReply;
        }
        return r;
      }),
    );
  };

  return (
    <li
      className="postCommentListItem"
      key={commentId}
      id={`comment${commentId}`}>
      <PostCommentCard
        creator={creator}
        commentId={commentId}
        onRemoveComment={onRemoveComment}
        userId={userId}
        profilePic={profilePic}
        name={name}
        selected={
          selected.type === 'comment' && selected.commentId === commentId
        }
        content={content}
        replyCount={
          !isRepliesVisible && replyCount > 0 ? replyCount : undefined
        }
        onLikeClick={async () => {
          toggleLikeComment();
          const res = await FeedAPI.updatecommentLike(commentId);
          if (res.status !== 200) {
            toggleLikeComment();
            message.error('Failed to like');
          }
        }}
        onReplyClick={() => {
          setSelected({
            type: 'comment',
            commentId,
            replyId: null,
            userId,
            userName: name,
          });
        }}
        onRepliesCountClick={() => {
          setIsRepliesVisible(true);
          getRepliesBefore();
        }}
        liked={liked}
        likeCount={likeCount}
        createdAt={createdAt}
        type="comment"
      />
      {isRepliesVisible && (
        <div className="postReplyListWrapper">
          {Boolean(remainingPreviousRepliesCount) && !loadingPrevious && (
            <ViewMoreComment
              title={`View ${remainingPreviousRepliesCount} older replies`}
              onClick={() => {
                setSelected({
                  type: null,
                  commentId: null,
                  replyId: null,
                  userName: null,
                  userId: null,
                });
                if (replies.length > 0) {
                  getRepliesBefore(replies[0]._id);
                } else {
                  getRepliesBefore();
                }
              }}
            />
          )}
          {loadingPrevious && <Loader />}
          <ul className="postReplyist">
            {replies.map((reply) => (
              <li
                key={reply._id}
                className="postReplyListItem"
                id={`reply${commentId}`}>
                <PostCommentCard
                  creator={creator}
                  onRemoveComment={async (replyId) => {
                    setReplies(replies.filter((rply) => rply._id !== replyId));
                    try {
                      await FeedAPI.deleteComment(replyId);
                      onUpdateReplyCount(replyCount - 1);
                    } catch (err) {
                      message.error('Failed to delete comment!');
                    }
                  }}
                  commentId={reply._id}
                  userId={reply.userId}
                  selected={selected.replyId === reply._id}
                  profilePic={reply.imgUrl || ''}
                  name={reply.userName || ''}
                  content={reply.text || ''}
                  replyCount={undefined}
                  onLikeClick={async () => {
                    toggleRepliesLike(reply);
                    const res = await FeedAPI.updatecommentLike(reply._id);
                    if (res.status !== 200) {
                      toggleRepliesLike(reply);
                      message.error('Failed to like');
                    }
                  }}
                  onReplyClick={() => {
                    setSelected({
                      type: 'reply',
                      commentId,
                      replyId: reply._id,
                      userId: reply.userId,
                      userName: reply.userName,
                    });
                  }}
                  liked={!!reply.liked}
                  likeCount={reply.likedBy.length || 0}
                  createdAt={reply.createdAt}
                  noOfLines={2}
                  type="reply"
                />
              </li>
            ))}
          </ul>
          <div>
            {Boolean(remainingNewerRepliesCount) && !loadingNext && (
              <ViewMoreComment
                onClick={() => {
                  setSelected({
                    type: null,
                    commentId: null,
                    replyId: null,
                    userName: null,
                    userId: null,
                  });
                  if (replies.length > 0) {
                    getRepliesAfter(replies[replies.length - 1]._id);
                  } else {
                    getRepliesAfter();
                  }
                }}
                title={`View ${remainingNewerRepliesCount} newer replies`}
              />
            )}
            {loadingNext && <Loader />}
          </div>
        </div>
      )}
    </li>
  );
};

export default forwardRef(PostComment);
