import './App.scss';

import React, { useEffect } from 'react';

import axios from 'axios';
import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import API from './api';
import config from './api/config';
import UserAPI from './api/UserAPI';
import ScreenRotate from './assets/img/screen.png';
import {
  setAppReady,
  setHostMetadata,
  setIp,
  setLoggedIn,
  setNativeDomain,
} from './context/appReducer';
import { useTheme } from './context/ThemeProvider';
import { setUserDetails } from './context/userReducer';
import Router from './Router';
import { REFRESH_TOKEN_KEY } from './shared/data';
import {
  useAppDispatch,
  useAppSelector,
} from './shared/hooks';
import { ICOLORS } from './shared/Styles';
import {
  getLocalData,
  hexToHSL,
  setLocalData,
} from './shared/utils';
import { ROUTES } from './types/routes';
import { OnboardingStatus } from './types/userTypes';

const App: React.FC = () => {
  const {
    appReady,
    hostMetadata: { appName, logo },
    isTagMango,
  } = useAppSelector((state) => state.app);
  const { updateColors } = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const metaTitle = `${appName} Mobile`;
  const metaDescription = isTagMango
    ? 'TagMango helps content creators monetise better. It helps them host live workshops and launch their courses.'
    : `Check out your favourite creator ${appName}, to access your favourite workshops and courses.`;

  const fetchUser = async () => {
    try {
      const user = await UserAPI.getLatestUserDetails();
      if (user.data.result) {
        const resp = user.data.result;
        dispatch(
          setUserDetails({
            name: resp.name,
            email: resp.email,
            phone: resp.phone,
            profilePic:
              resp.profilePicUrl ||
              'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
            type: resp.onboarding as OnboardingStatus,
            id: resp._id,
            userSlug: resp.userSlug,
            showInbox: resp.showInbox || false,
            approveStatus: resp.creatorAccessRequested || null,
            shortUrl: resp.shortUrl || '',
            disabledChat: resp.disabledChat,
            gstNumber: resp.gstNumber,
            currency: resp.currency,
            tmCommission: resp?.tmCommission || 0,
            country: resp.country,
            isEmailVerified: resp.isEmailVerified,
            requiresPostMigrationVerification:
              resp.requiresPostMigrationVerification,
            isBlockedFromCommunityEngagement:
              resp.isBlockedFromCommunityEngagement,
            score: resp.score,
          }),
        );
        dispatch(setLoggedIn(true));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAppReady(true));
  };

  const getPlatformMetadata = async () => {
    const metadata = await API.getHostDetails();
    dispatch(setHostMetadata(metadata.data.result));
    if (
      metadata.data.result?.colors &&
      Object.keys(metadata.data.result?.colors).length > 0
    ) {
      let colors: Partial<ICOLORS> = metadata.data.result.colors;
      if (colors.PRIMARY) {
        changeAntdTheme(colors.PRIMARY);
      }
      if (colors.PRIMARY && !colors.PRIMARY_LIGHT) {
        colors = Object.assign(
          {
            PRIMARY_LIGHT: colors.PRIMARY_LIGHT || hexToHSL(colors.PRIMARY),
          },
          colors,
        );
      }
      if (metadata.data.result?.creator._id) {
        API.setCreator(metadata.data.result.creator._id);
      }
      updateColors(colors);
    }
    return metadata.data.result;
  };

  const init = async () => {
    // get current host
    let hostName = window.location.hostname;
    hostName.replace('www.', '');
    if (hostName.startsWith('m.')) {
      hostName = hostName.substring(2);
    }
    // hostName = 'unfilteredhq.com';
    // hostName = 'app.financewsharan.com';
    // hostName = 'learn.consciousbreathinghub.com';
    // hostName = 'dikshaarora.com';
    // hostName = 'learn.divyanshudamani.xyz';

    if (
      hostName.includes('tagmango.com') ||
      hostName.includes('localhost') ||
      hostName.includes('192')
    ) {
      API.setHost(config.HOST);
      dispatch(setNativeDomain(config.HOST));
      if (config.HOST === 'testingwl.tagmango.in') {
        await getPlatformMetadata();
      }
    } else {
      API.setHost(hostName);
      dispatch(setNativeDomain(hostName));
      await getPlatformMetadata();
    }

    // check if user is logged in
    // let refreshToken = searchParams.get('token');
    // if (refreshToken) {
    //   localStorage.clear();
    //   setLocalData(refreshToken);
    //   localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    //   fetchUser();
    // } else {
    const token = getLocalData();
    if (token) {
      setLocalData(token);
      fetchUser();
    } else {
      dispatch(setAppReady(true));
    }
    // }
  };

  useEffect(() => {
    console.log(window.location.host);
    console.log(window.location);
    // when redirecting from checkout page, we get refresh token in query params
    const refreshToken = searchParams.get('token');
    // if refresh token is present in query params, set token in local storage
    // and fetch access token using this refresh token
    // then call init
    if (refreshToken) {
      // clearing local storage
      localStorage.clear();

      // setting refresh token in local storage for refreshToken API call
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      API.refreshToken({
        refreshToken,
        tempTokenTime: 6 * 60 * 60, // passing tempTokenTime as that the refresh token stays there, and not get deleted in the backend
        callback: () => {
          init();
          navigate(ROUTES.FEED);
        },
      });
    }
    // else call init
    else {
      init();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appReady) {
      document.getElementById('favicon_main')?.setAttribute('href', logo);
      document.getElementById('favicon_apple')?.setAttribute('href', logo);
      document.title = metaTitle;
      document
        .getElementById('app_description')
        ?.setAttribute('content', metaDescription);
    }
  }, [metaTitle, metaDescription, logo, appReady]);

  // Store user's current public IP for use in mock vdoCipher overlay
  useEffect(() => {
    axios
      .get('https://api64.ipify.org/?format=json')
      .then((res) => dispatch(setIp(res.data.ip)))
      .catch((err) => {
        // message.error("Failed to obtain user's IP address");
        console.log(JSON.stringify(err));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appHeight = () => {
    setTimeout(() => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  return (
    <>
      <div className="landscape__wrapper">
        <div className="landscape__content">
          <img src={ScreenRotate} alt="logo" />
          <p className="landscape__content__text">
            Please rotate your device to portrait mode
          </p>
        </div>
      </div>
      {appReady ? (
        <Router />
      ) : (
        <div className="loader__wrapper">
          <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(App);
