import './styles.scss';

import React, { useEffect, useState } from 'react';

import { message } from 'antd';

import API from '../../../api';
import { updateMedia } from '../../../context/mediaReducer';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';

interface Props {
  mediaId: string;
  vdoCipherId: string;
  mediaThumb?: string;
}

declare const window: any;

const VdoCipherPlayer: React.FC<Props> = ({
  mediaId,
  vdoCipherId,
  mediaThumb,
}) => {
  const { media } = useAppSelector((state) => state.media);
  const dispatch = useAppDispatch();
  const [playing, setPlaying] = useState(false);

  const handlePlayVideo = async () => {
    try {
      setPlaying(false);
      const resp = await API.getVideoCipherDetails(vdoCipherId);
      if (resp.status === 200) {
        setPlaying(true);
        const video = new window.VdoPlayer({
          otp: resp?.data.result?.otp,
          playbackInfo: resp?.data.result?.playbackInfo,
          theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
          // the container can be any DOM element on website
          container: document.getElementById(`${mediaId}`),
        });
        video.addEventListener(`load`, () => {
          video.play(); // this will auto-start the video
        });
        video.addEventListener(`play`, () => {
          dispatch(
            updateMedia({
              mediaId,
              mediaType: 'video',
              playerState: 'playing',
            }),
          );
        });
        video.addEventListener(`pause`, () => {
          dispatch(
            updateMedia({
              mediaId,
              mediaType: 'video',
              playerState: 'paused',
            }),
          );
        });
        video.addEventListener(`ended`, () => {
          dispatch(
            updateMedia({
              mediaId,
              mediaType: 'video',
              playerState: 'stopped',
            }),
          );
        });
      } else {
        setPlaying(false);
        message.error('Something went wrong');
      }
    } catch (error) {
      setPlaying(false);
      message.error('Something went wrong');
      console.log(error);
    }
  };

  useEffect(() => {
    if (media && media.mediaId !== mediaId && playing) setPlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, mediaId]);

  return (
    <>
      {!playing && (
        <div
          className="vdoCipherPlaceholder"
          style={{
            backgroundImage: `url(${mediaThumb})`,
          }}>
          <button type="button" onClick={handlePlayVideo}>
            <div className="playicon" />
          </button>
        </div>
      )}
      <div id={mediaId}> </div>
    </>
  );
};

export default React.memo(VdoCipherPlayer);
