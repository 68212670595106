import React, { useEffect, useState } from 'react';

import { Avatar, message, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { MdCameraAlt } from 'react-icons/md';

import API from '../../../api';
import UserAPI from '../../../api/UserAPI';
import ImgCrop from '../../../components/ImgCrop/ImgCrop';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import { setUserDetails } from '../../../context/userReducer';
import { validImageExtensions } from '../../../shared/data';
import { useAppDispatch } from '../../../shared/hooks';
import { getBase64 } from '../../../shared/utils';

const MAX_FILE_SIZE = 1024;

interface Props {
  firstName: string;
  profileImage?: string;
}

const ProfileHeading: React.FC<Props> = ({ firstName, profileImage }) => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();

  const [file, setFile] = useState<RcFile>();
  const [loading, setLoading] = useState<boolean>(false);

  const uploadProfileImage = async () => {
    if (file) {
      try {
        setLoading(true);
        const response = await API.uploadPicture('public', file);
        if (response.status === 200 && response.data.result.compressed) {
          // logThis('updating account with url', response.data.result);
          try {
            const resp = await UserAPI.updateAccountDetails({
              profilePicUrl: response.data.result.compressed,
              profilePicUrlUncompressed: response.data.result.uncompressed,
            });
            if (resp.status === 200) {
              dispatch(
                setUserDetails({ profilePic: response.data.result.compressed }),
              );
              message.success('Profile picture updated successfully');
            } else {
              message.error('Error uploading image.');
            }
          } catch (err) {
            message.error('Error uploading image.');
            // logThis('internal error', JSON.stringify(err));
          }
        } else {
          // logThis('Error uploading image--->', JSON.stringify(response));
          message.error('Error uploading image.');
        }
      } catch (err) {
        // logThis(Error)
      } finally {
        setLoading(false);
        setFile(undefined);
      }
    }
  };

  useEffect(() => {
    if (file) {
      getBase64(file, () => {
        uploadProfileImage();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const beforeUpload = (inputFile: RcFile) => {
    const isValidExtension =
      validImageExtensions.indexOf(inputFile.type) !== -1;

    if (!isValidExtension) {
      message.error('You can only valid image type!');
    }

    const isValidSize = inputFile.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isValidSize) {
      message.error('Image must smaller than 1024MB!');
    }

    if (isValidExtension && isValidSize) {
      setFile(inputFile);
    }
    return false;
  };

  return (
    <div className="profileHeader">
      <div className="profileName">
        <Typography.Title level={3} style={{ margin: 0 }}>
          Hello,
        </Typography.Title>
        <Typography.Title
          ellipsis={{
            rows: 1,
            expandable: false,
          }}
          level={1}
          className="profileName__name"
          style={{ margin: 0 }}>
          {firstName}
        </Typography.Title>
      </div>
      {/* @ts-ignore */}
      <ImgCrop rotate grid aspect={1}>
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          accept="*">
          <div className="avatarHolder">
            {loading ? (
              <Loader
                size="large"
                style={{
                  width: '64px',
                }}
              />
            ) : (
              <Avatar size={64} src={profileImage} />
            )}

            <div className="camera__container">
              <MdCameraAlt color={colors.BACKGROUND} size={20} />
            </div>
          </div>
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default React.memo(ProfileHeading);
