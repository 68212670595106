import './styles.scss';

import { useEffect, useMemo, useState } from 'react';

import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  message,
  Typography,
} from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import HtmlParser from 'html-react-parser';
import moment from 'moment';
import ReactLinkify from 'react-linkify';
import { useParams } from 'react-router-dom';

import API from '../../api';
import UserAPI from '../../api/UserAPI';
import TickSvg from '../../assets/svg/TickSvg';
import CustomImage from '../../components/CustomImage/CustomImage';
import HeaderIcon from '../../components/Header/actions/HeaderIcon';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import ShareBottomSheet from '../../components/Modals/ShareBottomSheet/ShareBottomSheet';
import BitmovinPlayer from '../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import { useTheme } from '../../context/ThemeProvider';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { emailRegex, navigateToOnboardingScreen } from '../../shared/utils';
import { IResponseType } from '../../types/baseTypes';
import { ActiveMango, ICreatorDetailsResponse } from '../../types/userTypes';
import CreatorSocialActions from './CreatorSocialActions';
import MangoCard from './MangoCard/MangoCard';

const CreatorProfile: React.FC = () => {
  const { colors } = useTheme();
  const { id: creatorId, slug } = useParams();
  const navigate = useAppNavigate();
  const {
    id: userId,
    type: onboardingStatus,
    email,
  } = useAppSelector((state) => state.user);
  const {
    hostMetadata: { offeringTitle },
    deeplinkUrl,
    shortUrl,
    isTagMango,
  } = useAppSelector((state) => state.app);
  const [creatorProfileDetails, setCreatorProfileDetails] =
    useState<IResponseType<ICreatorDetailsResponse>>();
  const [notifyEmail, setNotifyEmail] = useState(email);
  const [isNotifyCalling, setIsNotifyCalling] = useState(false);
  const { token } = useAppSelector((state) => state.app);
  const [subscriberMangoes, setSubscriberMangoes] = useState<any[]>([]);
  const [shareBottomSheetVisible, setShareBottomSheetVisible] = useState(false);

  const goBack = () => {
    if (navigate.canGoBack()) {
      navigate.goBack();
    } else {
      navigateToOnboardingScreen(onboardingStatus, navigate);
    }
    return true;
  };

  const getDetailsOfCreator = async () => {
    if (!creatorId && !slug) return;
    let apiCall;
    if (slug) {
      apiCall = UserAPI.getCreatorsDetailsBySlug(slug);
    }
    if (creatorId) {
      apiCall = UserAPI.getCreatorsDetails(creatorId);
    }
    if (apiCall === undefined) return;
    apiCall
      .then(async (response) => {
        setCreatorProfileDetails(response.data);
        if (token && token !== '') {
          const res = (
            await UserAPI.getSubscriberDetailsWithActiveMango(
              response.data?.result.creatordetails._id,
            )
          ).data;
          if (res?.result) {
            setSubscriberMangoes([...res?.result?.subscriptions]);
          }
          if (response.data?.result?.creatordetails?._id !== userId) {
            UserAPI.updateProfileViews(
              response.data?.result?.creatordetails?._id,
              userId,
            );
          }
        }
      })
      .catch(() => {
        message.error("Couldn't find creator!!");
        goBack();
      });
  };

  useEffect(() => {
    if (!creatorId && !slug) {
      message.error('Creator not found!!');
      goBack();
      return;
    }
    getDetailsOfCreator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId]);

  const getTitle = () => {
    const name: string =
      creatorProfileDetails?.result.creatordetails.name.split(' ')[0] ??
      'Loading';
    return name.length > 12 ? `${name.substring(0, 12)}...` : name;
  };

  const notifyUser = async () => {
    if (
      notifyEmail &&
      emailRegex.test(notifyEmail) &&
      creatorProfileDetails?.result.creatordetails._id
    ) {
      try {
        setIsNotifyCalling(true);
        const res = await UserAPI.notifyUserOnMango(
          notifyEmail,
          creatorProfileDetails?.result.creatordetails._id,
        );
        if (res.status === 200) {
          message.success(
            `You'll be notified once creator creates a ${offeringTitle}`,
          );
        } else {
          message.info('You are already added to the mailing list!');
        }
      } catch (err) {
        message.error('Something went wrong!');
      } finally {
        setIsNotifyCalling(false);
      }
    } else {
      message.error('Please enter valid email!');
    }
  };

  const shareUrl = useMemo(() => {
    let url = `${deeplinkUrl}creator/`;
    if (creatorProfileDetails?.result.creatordetails.shortUrl) {
      url = `${shortUrl}${creatorProfileDetails?.result.creatordetails.shortUrl}`;
    } else if (creatorProfileDetails?.result.creatordetails.userSlug) {
      url += creatorProfileDetails?.result.creatordetails.userSlug;
    } else {
      url += `profile/${creatorProfileDetails?.result.creatordetails._id}`;
    }
    return url;
  }, [
    creatorProfileDetails?.result.creatordetails._id,
    creatorProfileDetails?.result.creatordetails.shortUrl,
    creatorProfileDetails?.result.creatordetails.userSlug,
    deeplinkUrl,
    shortUrl,
  ]);

  const listComponent = (str: string): React.ReactNode => (
    <div className="pointer">
      <TickSvg
        circleColor={colors.PRIMARY}
        backgroundColor={colors.BACKGROUND}
      />
      <Typography.Text className="pointerTitle">{str}</Typography.Text>
    </div>
  );

  const mangoes = useMemo(() => {
    if (!creatorProfileDetails?.result.activeMangoes) return [];
    let list = creatorProfileDetails.result.activeMangoes.filter(
      (item) =>
        item.title !== 'Public' &&
        !item.isPublic &&
        !item.isStopTakingPayment &&
        !item.isDeleted,
    );
    const sortList = (l: ActiveMango[]) => {
      l.sort((a, b) => {
        return new Date(b.start).getTime() - new Date(a.start).getTime();
      });
      return l;
    };

    // get todays list using moment and sort it
    const today = new Date();
    let todayList = list.filter((item) =>
      moment(item.start).isSame(today, 'day'),
    );
    todayList = sortList(todayList);
    // get upcoming list and sort it
    let upcomingList = list.filter((item) =>
      moment(item.start).isAfter(today, 'day'),
    );
    upcomingList = sortList(upcomingList);
    // get past list and sort it
    let pastList = list.filter((item) =>
      moment(item.start).isBefore(today, 'day'),
    );
    pastList = sortList(pastList);
    list = [...todayList, ...upcomingList, ...pastList];
    return list || [];
  }, [creatorProfileDetails]);

  return (
    <>
      <div className="pageRoot">
        <Header
          handleBack={goBack}
          title={`${getTitle()}'s profile`}
          actionItems={[
            <HeaderIcon
              icon="share"
              onClick={() => setShareBottomSheetVisible(true)}
            />,
          ]}
        />
        <div className="pageContent creatorProfile">
          {creatorProfileDetails ? (
            <>
              <div className="creatorProfile__image__container">
                <CustomImage
                  className="creatorProfile__image--blur"
                  src={
                    creatorProfileDetails.result.creatordetails.profilePicUrl ||
                    creatorProfileDetails.result.creatordetails
                      .profilePicUrlUncompressed
                  }
                  alt="creator"
                />
                <Avatar
                  size={120}
                  src={
                    creatorProfileDetails.result.creatordetails.profilePicUrl ||
                    creatorProfileDetails.result.creatordetails
                      .profilePicUrlUncompressed
                  }
                />
              </div>
              <Typography.Title
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
                level={3}
                className="creatorProfile__name">
                {creatorProfileDetails.result.creatordetails.name || ''}
              </Typography.Title>
              {creatorProfileDetails?.result?.creatordetails
                ?.showSubscriberCount ? (
                <Typography.Title
                  level={5}
                  className="creatorProfile__subscribers">
                  {creatorProfileDetails?.result.subscriberCount} subscribers
                </Typography.Title>
              ) : null}
              <CreatorSocialActions
                creator={creatorProfileDetails?.result.creatordetails}
              />
              <Divider />
              {mangoes.length > 0 ? (
                <div className="creatorProfile__mangoList">
                  <Typography.Title
                    level={5}
                    className="creatorProfile__mangoList__title">
                    Select {offeringTitle}
                  </Typography.Title>
                  <Typography.Paragraph className="creatorProfile__mangoList__description">
                    Subscribe to {getTitle()}’s {offeringTitle} and get access
                    to insane learnings and exclusive content!
                  </Typography.Paragraph>
                  <EasyVirtualizedScroller
                    useParentScrollElement
                    hasMore={false}>
                    {mangoes.map((item) => (
                      <MangoCard
                        mangoDetails={item}
                        isMe={
                          userId
                            ? userId ===
                              creatorProfileDetails?.result.creatordetails._id
                            : false
                        }
                        subscriberMangoes={subscriberMangoes}
                        onBtnPress={async () => {
                          let url = isTagMango
                            ? `${deeplinkUrl}mangocheckout/${item._id}`
                            : `${deeplinkUrl}web/checkout/${item._id}`;
                          url += '?purchaseNow=true';
                          if (token) {
                            const resp = await API.fetchNewToken(
                              undefined,
                              1800,
                            );
                            if (resp) {
                              const sessionToken = resp.data.result.accessToken;
                              if (sessionToken)
                                url += `&sessionToken=${encodeURIComponent(
                                  sessionToken,
                                )}`;
                              window.open(url);
                            } else {
                              message.error('Could not fetch token!');
                            }
                          } else {
                            window.open(url);
                          }
                        }}
                      />
                    ))}
                  </EasyVirtualizedScroller>
                </div>
              ) : (
                <div className="creatorProfile__noMango__container">
                  {creatorProfileDetails.result.creatordetails._id ===
                  userId ? (
                    <Typography.Title
                      level={4}
                      className="creatorProfile__noMango__title">
                      No Active Mangoes.
                    </Typography.Title>
                  ) : (
                    <div className="creatorProfile__noMango__wrapper">
                      <svg
                        className="creatorProfile__noMango__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="64"
                        height="64.001"
                        viewBox="0 0 64 64.001">
                        <g
                          id="sad-face-in-rounded-square_1_"
                          data-name="sad-face-in-rounded-square (1)"
                          transform="translate(0 0)">
                          <path
                            id="Path_731"
                            data-name="Path 731"
                            d="M54.639,9.364a32.015,32.015,0,1,0,0,45.273A32.05,32.05,0,0,0,54.639,9.364ZM51.146,51.146a27.077,27.077,0,1,1,0-38.292A27.107,27.107,0,0,1,51.146,51.146Zm-4.671-6.491a1.81,1.81,0,0,1-3.323,1.437A11.324,11.324,0,0,0,32.64,39.447a11.229,11.229,0,0,0-10.573,6.64A1.81,1.81,0,1,1,18.723,44.7,14.837,14.837,0,0,1,32.64,35.827,14.939,14.939,0,0,1,46.475,44.655ZM20.058,23.336A3.742,3.742,0,1,1,23.8,27.078,3.742,3.742,0,0,1,20.058,23.336Zm16.967,0a3.742,3.742,0,1,1,3.743,3.743A3.744,3.744,0,0,1,37.025,23.336Z"
                            transform="translate(0 0)"
                            fill="#ced0d4"
                          />
                        </g>
                      </svg>

                      <Typography.Title
                        level={4}
                        className="creatorProfile__noMango__title">
                        Oops! The Creator has No Active Mangoes.
                      </Typography.Title>
                      <Typography.Text className="creatorProfile__noMango__description">
                        Get notified as soon as the creator creates a mango!
                      </Typography.Text>
                      <Form
                        style={{
                          width: '100%',
                          maxWidth: '425px',
                          margin: '24px auto 0',
                        }}
                        disabled={isNotifyCalling}
                        initialValues={{
                          email:
                            creatorProfileDetails?.result?.creatordetails
                              ?.email,
                        }}
                        onFinish={notifyUser}>
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          label=""
                          name="email">
                          <Input
                            placeholder="Enter email"
                            autoComplete="off"
                            size="large"
                            disabled={Boolean(
                              creatorProfileDetails?.result?.creatordetails
                                ?.email,
                            )}
                            className="notifyInput"
                            value={notifyEmail || ''}
                            onChange={(e) => {
                              setNotifyEmail(e.target.value);
                            }}
                            suffix={
                              <Button
                                className="siteBtn siteBtnLink sm"
                                type="primary"
                                htmlType="submit"
                                style={{ padding: 0 }}>
                                NOTIFY ME
                              </Button>
                            }
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                </div>
              )}
              <Divider />
              <div className="creatorProfile__about__wrapper">
                <Typography.Title
                  level={3}
                  className="creatorProfile__about__title">
                  About {getTitle()}
                </Typography.Title>
                {(creatorProfileDetails.result.creatordetails.about ||
                  creatorProfileDetails.result.creatordetails.videoUrl) && (
                  <div className="creatorProfileDetailsWrapper">
                    {creatorProfileDetails.result.creatordetails.videoUrl && (
                      <div className="creatorProfileDetailsVideowrapper">
                        <BitmovinPlayer
                          mediaUrl={
                            creatorProfileDetails.result.creatordetails.videoUrl
                          }
                          compressedUrl={
                            creatorProfileDetails.result.creatordetails.videoUrl
                          }
                          mediaId={
                            creatorProfileDetails.result.creatordetails._id
                          }
                          isCompressDone
                          isCompressedUrl
                        />
                      </div>
                    )}
                    {creatorProfileDetails.result.creatordetails.about && (
                      <>
                        <div className="userAboutParagraphWrapper">
                          <ReactLinkify>
                            <Typography.Paragraph
                              className="creatorProfileDetails"
                              style={{ whiteSpace: 'pre-wrap' }}>
                              {HtmlParser(
                                creatorProfileDetails.result.creatordetails
                                  .styledAbout ||
                                  creatorProfileDetails.result.creatordetails.about.trim(),
                              )}
                            </Typography.Paragraph>
                          </ReactLinkify>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <Divider />
              <div className="becomeSubscriber">
                <Typography.Title level={4} className="becomeSubscriberTitle">
                  Become a subscriber to
                </Typography.Title>
                <div className="becomeSubscriberPointers">
                  {listComponent('Unlock exclusive content')}
                  {listComponent('Be a part of the community')}
                  {listComponent('Unlimited access from anywhere')}
                </div>
              </div>
            </>
          ) : (
            <Loader
              size="large"
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: '45px',
              }}
            />
          )}
        </div>
      </div>
      <ShareBottomSheet
        showModal={shareBottomSheetVisible}
        closeModal={() => setShareBottomSheetVisible(false)}
        shareLink={shareUrl}
        title="Check out this profile"
      />
    </>
  );
};

export default CreatorProfile;
