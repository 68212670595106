import * as React from 'react';

import {
  Avatar,
  Button,
  Input,
  message,
  Typography,
} from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import { MdSend } from 'react-icons/md';

import CourseAPI from '../../../api/CourseAPI';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { Creator } from '../../../types/courseTypes';
import { IQNA } from '../../../types/qnaTypes';
import QNAItem from '../QNAItem/QNAItem';

interface CommentsSectionProps {
  chapterId: string;
  creator: Creator;
}

interface ICommentsState {
  comments: IQNA[];
  hasMore: boolean;
  page: number;
  loading: boolean;
  refreshing: boolean;
}

const defaultState: ICommentsState = {
  loading: false,
  page: 1,
  hasMore: true,
  comments: [],
  refreshing: false,
};

const PAGE_LIMIT = 10;

const CourseCommentsSection: React.FC<CommentsSectionProps> = ({
  chapterId,
  creator,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);

  // const inputRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.createRef<HTMLTextAreaElement>();

  const [commentsState, setCommentsState] =
    React.useState<ICommentsState>(defaultState);
  const [text, setText] = React.useState<string>('');
  const [isSaving, setIsSaving] = React.useState(false);

  const getCommentsPaginated = React.useCallback(
    async (
      pageCount: number,
      commentsList = commentsState.comments,
      hasMore = commentsState.hasMore,
      loading = commentsState.loading,
      currState = commentsState,
    ) => {
      if (hasMore && !loading) {
        setCommentsState({ ...currState, loading: true });
        try {
          const response = await CourseAPI.getCommentsList(
            chapterId,
            pageCount,
            PAGE_LIMIT,
          );
          const res = response.data;
          let newState = { ...currState, loading: false, refreshing: false };
          if (response.status === 200) {
            if (res.result && res.result.length) {
              const updatedComments =
                pageCount > 1
                  ? [...(commentsList || []), ...res.result]
                  : [...res.result];
              newState = {
                ...newState,
                comments: updatedComments,
                page: pageCount + 1,
                hasMore: res.result.length === PAGE_LIMIT,
              };
            } else {
              newState = {
                ...newState,
                comments: [...(commentsList || [])],
                hasMore: false,
              };
            }
          }
          setCommentsState({ ...commentsState, ...newState });
        } catch (error: any) {
          setCommentsState({ ...commentsState, loading: false });
        }
      }
    },
    [chapterId, commentsState],
  );

  const loadQNA = React.useCallback(() => {
    const newState: ICommentsState = {
      comments: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setCommentsState((prev) => ({
      ...prev,
      comments: [],
      hasMore: true,
      page: 1,
    }));
    getCommentsPaginated(1, [], true, false, newState);
  }, [getCommentsPaginated]);

  const removeQNAItem = async (commentsId: string) => {
    const ind = commentsState.comments.findIndex((i) => i._id === commentsId);
    const item = commentsState.comments[ind];
    setCommentsState((prev) => ({
      ...prev,
      comments: prev.comments.filter((comments) => comments._id !== commentsId),
    }));
    const m = message.loading('Deleting comment...');
    try {
      const res = await CourseAPI.removeQNA(commentsId);
      if (res.status === 200) {
        m();
        message.success('Comment successfully removed!!!');
      }
    } catch (error) {
      console.log(error);
      m();
      message.error('Error removing comment!!!');
      setCommentsState((prev) => ({
        ...prev,
        comments: prev.comments.splice(ind, 0, item),
      }));
    } finally {
      m();
    }
  };

  const onSend = React.useCallback(async () => {
    const comment = (text || '').trim();

    if (!comment) {
      return;
    }

    // setIsInputBoxVisible(false);
    setIsSaving(true);
    try {
      const data = new FormData();
      data.append('title', '');
      data.append('description', comment.trim());
      data.append('type', 'comment');
      const resp = await CourseAPI.createQnA(chapterId, data);
      if (resp.status === 200) {
        // inputRef.current?.clear();
        setCommentsState((prev) => ({
          ...prev,
          comments: [resp.data.result, ...prev.comments],
        }));
        setText('');
      } else {
        message.error('Failed to post comment');
      }
    } catch (err) {
      message.error('Failed to post comment');
    } finally {
      setIsSaving(false);
    }
  }, [chapterId, text]);

  React.useEffect(() => {
    loadQNA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterId]);

  const footer = React.useMemo(() => {
    if (commentsState.loading || commentsState.hasMore)
      return (
        <Loader
          style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}
        />
      );
    if (commentsState.comments.length === 0)
      return (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '45px 0',
          }}>
          Whoops! No comments posted!
        </Typography.Paragraph>
      );
    return (
      <Typography.Paragraph
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '20px 0',
        }}>
        That’s it! No more comments to show.
      </Typography.Paragraph>
    );
  }, [
    commentsState.hasMore,
    commentsState.loading,
    commentsState.comments.length,
  ]);

  const header = React.useMemo(
    () => (
      <div>
        <Typography.Title level={4} className="chapter__section__title">
          Comments
        </Typography.Title>
        <div className="qna__section__header commentInputBox">
          <Avatar
            src={userDetails.profilePic || ''}
            size={36}
            style={{
              flexShrink: 0,
            }}
          />
          <Input.TextArea
            ref={inputRef}
            className="commentInputBox__input"
            placeholder="Write a comment..."
            autoSize
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={1}
            style={{ resize: 'none', border: 'none', boxShadow: 'none' }}
          />
          <Button
            disabled={isSaving}
            className="commentInputBox__sendIcon"
            onClick={onSend}>
            {isSaving ? (
              <Loader size="small" />
            ) : (
              <MdSend size={20} color={colors.BACKGROUND} />
            )}
          </Button>
        </div>
      </div>
    ),
    [
      colors.BACKGROUND,
      inputRef,
      isSaving,
      onSend,
      text,
      userDetails.profilePic,
    ],
  );

  return (
    <>
      {header}
      {commentsState.comments.length > 0 ? (
        <EasyVirtualizedScroller
          useParentScrollElement
          hasMore={commentsState.hasMore}
          onLoadMore={() =>
            getCommentsPaginated(commentsState.page, commentsState.comments)
          }>
          {commentsState.comments.map((item) => (
            <QNAItem
              type="comment"
              key={item._id}
              question={item.title}
              description={item.description}
              createdAt={item.createdAt}
              imgUrl={item.imgUrl}
              userName={item.userName}
              attachments={item.attachments}
              replyCount={item.replyCount}
              containerStyle={{
                borderBottom: `1px solid ${colors.BORDER}`,
              }}
              showMenu={
                userDetails.id === item.userId ||
                userDetails.id === creator._id ||
                userDetails.type === 'creator_restricted'
              }
              showReply
              isAnswered={item.answered}
              onClick={() => {}}
              onRemoveItem={() => {
                removeQNAItem(item._id);
              }}
            />
          ))}
        </EasyVirtualizedScroller>
      ) : null}
      {footer}
    </>
  );
};

export default React.memo(CourseCommentsSection);
