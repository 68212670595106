import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { message } from 'antd';

import API from '../../../api';
import MessageHeader from '../../../components/Header/MessageHeader';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import {
  useAppNavigate,
  useAppSelector,
} from '../../../shared/hooks';
import { HostMetadata } from '../../../types/HostMetadata';
import { CreatorDetails } from '../../../types/userTypes';

interface Props {
  creatorId: string;
}

const StorefrontPreview: React.FC<Props> = (props) => {
  const { colors } = useTheme();
  const navigate = useAppNavigate();

  const {
    hostMetadata: hostData,
    isTagMango,
    deeplinkUrl,
  } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [storefrontId, setStorefrontId] = useState<string>('');
  const [creatorDetails, setCreatorDetails] = useState<CreatorDetails>();
  const [hostMetadata, setHostMetadata] = useState<HostMetadata | null>();

  const { logo, title } = useMemo(() => {
    if (hostMetadata && !hostMetadata.host.includes('tagmango.com')) {
      return {
        logo: hostMetadata.logo,
        title: hostMetadata.appName,
        color: hostMetadata.colors?.PRIMARY || colors.PRIMARY,
      };
    } else {
      return {
        logo: creatorDetails?.profilePicUrl,
        title: creatorDetails?.name,
        color: colors.PRIMARY,
      };
    }
  }, [
    colors.PRIMARY,
    creatorDetails?.name,
    creatorDetails?.profilePicUrl,
    hostMetadata,
  ]);

  const fetchData = async (creatorId: string | undefined) => {
    try {
      setIsLoading(true);
      const response = await API.getCurrentCountry();
      let currentCountry = 'IN';
      if (response.data.code === 0) {
        const data = response?.data?.result;
        currentCountry = JSON.parse(data as string)?.countryCode;
        // setCountryCode(JSON.parse(data as string)?.countryCode);
      }
      const resp = await API.getStorefrontDetails({
        creatorId,
        currentCountry,
      });
      if (resp.status === 200) {
        const storefront = resp.data.result;
        setStorefrontId(storefront._id);
        if (isTagMango) {
          // @ts-ignore
          setCreatorDetails({
            name: userDetails.name || '',
            profilePicUrl: userDetails.profilePic || '',
          });
        }
        setHostMetadata(hostData);
      } else {
        message.error('Error fetching storefront!');
        navigate.goBack();
      }
    } catch (err) {
      console.log('error fetching storefront: ', err);
      message.error('Storefront not found!');
      navigate.goBack();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.creatorId) {
      fetchData(props.creatorId);
    } else {
      message.error('Storefront not found!');
      navigate.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div>
      {/* <DynamicHeader path={ROUTES.STOREFRONT} /> */}
      <MessageHeader title={title || 'Loading...'} url={logo} message="" />
      {isLoading ? (
        <Loader
          size="large"
          style={{
            textAlign: 'center',
            marginTop: 100,
            width: '100%',
          }}
        />
      ) : (
        <iframe
          src={`${deeplinkUrl}web/lite/store/${storefrontId}?hideHeader=true`}
          title="storefront"
          style={{
            width: '100%',
            height: 'calc(100vh - 60px)',
            border: 'none',
            overflow: 'hidden',
          }}
        />
      )}
    </div>
  );
};

export default React.memo(StorefrontPreview);
