export interface ICOLORS extends Record<keyof typeof LIGHT_COLORS, string> {}

export const LIGHT_COLORS = {
  PRIMARY: '#F18926',
  PRIMARY_LIGHT: '#FDF2E7',
  SECONDARY: '#CED0D4',
  TERTIARY: '#F2F3F7',
  BORDER: '#EAEAEC',
  GRAY_LIGHT: '#ebedf2',
  GRAY_LIGHTER: '#f0f2f5',
  PAGE_BG: '#D9DBE0',
  DANGER: '#ea2440',
  WARNING: '#ffcc00',
  BLUE: '#3b86e0',
  LINK: '#1890FF',
  INFO_ALT: '#3480eb',
  GREEN: '#49ba69',
  DARKBLUE: '#0f2137',
  TEXT1: '#212121',
  TEXT2: '#424242',
  TEXT3: '#616161',
  TEXT4: '#9E9E9E',
  TEXT5: '#BDBDBD',
  CHAT_AUDIO_BG: '#E2AA5D',
  ICON: '#4A4A59',
  BACKGROUND: '#FFFFFF',
  SURFACE_PAGE: '#F7F8FA',
  SHADOW: '#000000',
  SKELETON_BG: '#dedede',
  SKELETON_HIGHLIGHT: '#f7f7f7',
  CREATOR_LABEL: '#F5CB52',
  BOX_SHADOW: 'rgba(0, 0, 0, 0.1)',
};

export const DARK_COLORS: ICOLORS = {
  PRIMARY: '#F18926',
  PRIMARY_LIGHT: '#F1892620',
  SECONDARY: '#0a192f',
  TERTIARY: '#2a3d5e',
  BORDER: '#152745',
  GRAY_LIGHT: '#112240',
  GRAY_LIGHTER: '#233554',
  PAGE_BG: '#262e47',
  DANGER: '#ea2440',
  WARNING: '#ffcc00',
  BLUE: '#3b86e0',
  LINK: '#1890FF',
  INFO_ALT: '#3480eb',
  GREEN: '#49ba69',
  DARKBLUE: '#8892b0',
  TEXT1: '#e6f1ff',
  TEXT2: '#ccd6f6',
  TEXT3: '#a8b2d1',
  TEXT4: '#8892b0',
  TEXT5: '#495670',
  CHAT_AUDIO_BG: '#E2AA5D',
  ICON: '#cad1eb',
  BACKGROUND: '#020c1b',
  SURFACE_PAGE: '#080707',
  SHADOW: '#f0f5fc',
  SKELETON_BG: '#2a3657',
  SKELETON_HIGHLIGHT: '#3e547a',
  CREATOR_LABEL: '#0a192f',
  BOX_SHADOW: 'rgba(0, 0, 0, 0.1)',
};

export const CHAT_NAME_COLORS = [
  '#ffbc38',
  '#ff72a1',
  '#fc9775',
  '#25d366',
  '#a5b337',
  '#42c7b8',
  '#f15c6d',
  '#15A0DA',
  '#e91e63',
  '#5856D6',
  '#FF3B30',
] as const;
